export var listValuesParamsEarthW =
    [
    "ValuesParamsEarthSVG1",
    "ValuesParamsEarthSVG3Title",
    "ValuesParamsEarthSVG3Platform2",
    "ValuesParamsEarthSVG2Title",
    "ValuesParamsPlatformSVG1Platform2",
    "ValuesParamsEarthSVG1Title",
    "ValuesParamsEarthworkCleaningHeight",
    "ValuesParamsEarthworkPerpolationFactor",
    "ValuesParamsEarthworkShrinkageFactor",
    "ValuesParamsEarthworkLoanMtd",
    "ValuesParamsEarthworkBootOutMtd",
    "ValuesParamsEarthSVG2",
    "ValuesParamsEarthworkFillHeight",
    "ValuesParamsEarthworkFillFactor",
    "ValuesParamsEarthworkFillEnbankmentSlope",
    "ValuesParamsEarthworkFillEnbankmentWidth",
    "ValuesParamsEarthSVG3",
    "ValuesParamsEarthworkCutHeight",
    "ValuesParamsEarthworkCutFactor",
    "ValuesParamsEarthworkCutEnbankmentSlope",
    "ValuesParamsEarthworkCutEnbankmentWidth",
    "ValuesParamsEarthworkSecondCatRate",
    "ValuesParamsEarthworkThirdCatRate",
    "divider1",
    "divider2",
    "divider3",
    "divider4",
    "divider5",
    "divider6",
]