<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformSVG6Title">
        <strong> {{ selectedLanguage['platformTunnel_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <br />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 472.19 116.15" id="ValuesParamsPlatformSVG6">
      <title>{{ selectedLanguage['platformTunnel_component_text2'] }}</title>
      <g id="Terreno">
        <path
          class="cls-1"
          d="M6.39,121c45.1-.63,84.3-19.67,125.12-38.84L227.66,37c20.19-9.48,40.57-19,62.29-24.1,17.71-4.13,36-5.21,54.19-4.79,11.2.27,22.57,1.13,33.06,5.06C397.64,20.8,412,38.89,425.37,56.18l30.06,39c5.58,7.24,12.38,23.86,19.95,29l-334.32-4.59L3.19,121.46"
          transform="translate(-3.19 -7.99)"
        />
      </g>
      <g id="Aterro">
        <path
          class="cls-2"
          d="M97.45,97.78c-12.6,6.29-25.63,12-38.79,17-2.35.89-4.7,1.76-7.1,2.51-2.84.89-5.48.73-8.39,1.28-8,1.49-31.92,4.28-40,2.85l.66-23.54Z"
          transform="translate(-3.19 -7.99)"
        />
        <path
          class="cls-2"
          d="M37.63,109.62"
          transform="translate(-3.19 -7.99)"
        />
        <path
          class="cls-2"
          d="M49.82,96.94"
          transform="translate(-3.19 -7.99)"
        />
        <path class="cls-2" d="M4,96.55" transform="translate(-3.19 -7.99)" />
      </g>
      <g id="Corte">
        <path
          class="cls-3"
          d="M99.16,97.28c0,.15.23.29.61.41a11.38,11.38,0,0,0,3,0H181v-39a9.43,9.43,0,0,0,1.38-.22c-6.52,2.31-12.53,5.53-18.74,8.45q-9.71,4.56-19.69,8.45c-1.69.66-24,11.8-24.51,12q-6.24,2.61-12.35,5.54c-2.07,1-4.13,2-6.18,3.07C100.17,96.33,99,96.83,99.16,97.28Z"
          transform="translate(-3.19 -7.99)"
        />
        <path
          class="cls-3"
          d="M456.42,96.34l-4.14-5.44-3.39-4.47c-1-1.36-2.05-2.71-3-4.1-.71-1-1.39-2-2.1-3-1.37-2-2.82-3.84-4.27-5.74l-1.67-2.17c-1.35-1.76-2.7-3.53-4.13-5.23-1.29-1.55-2.76-2.94-4.17-4.39V97.55h27.31"
          transform="translate(-3.19 -7.99)"
        />
      </g>
      <g id="Tunnel">
        <polyline class="cls-4" points="177.19 86.32 423.2 86.32 423.2 88.78" />
        <!-- <text class="cls-5" transform="translate(289.63 79.55)">
          <tspan class="cls-6">T</tspan>
          <tspan x="5.42" y="0">unnel</tspan>
        </text> -->
      </g>
      <g id="Grade">
        <line class="cls-7" x1="452.87" y1="89.56" x2="0.66" y2="89.93" />
      </g>
      <g id="Medidas">
        <path class="cls-8" d="M181.38,98" transform="translate(-3.19 -7.99)" />
        <path
          class="cls-8"
          d="M181.38,58.69"
          transform="translate(-3.19 -7.99)"
        />
        <path
          class="cls-8"
          d="M333.23,96.94"
          transform="translate(-3.19 -7.99)"
        />
        <path class="cls-8" d="M333.23,8" transform="translate(-3.19 -7.99)" />
        <text class="cls-5" transform="translate(398.4 74.02)">
          {{ currentCalc.values_params.earthwork.min_struc_height_down }} m
        </text>
        <text class="cls-5" transform="translate(184.19 76.02)">
          {{ currentCalc.values_params.earthwork.min_struc_height_down }} m
        </text>
        <text class="cls-5" transform="translate(334.15 53.23)">
          &gt;{{ currentCalc.values_params.earthwork.max_cut }} m
        </text>
        <line class="cls-8" x1="426.56" y1="53.9" x2="426.56" y2="89.58" />
        <line class="cls-8" x1="427.94" y1="89.55" x2="454.31" y2="89.55" />
        <line class="cls-9" x1="332.15" y1="87.19" x2="332.15" y2="1.57" />
        <polygon
          class="cls-10"
          points="333 86.19 332.15 87.04 331.31 86.19 331.31 86.91 332.15 87.76 333 86.91 333 86.19"
        />
        <polygon
          class="cls-10"
          points="333 2.57 332.15 1.72 331.31 2.57 331.31 1.85 332.15 1 333 1.85 333 2.57"
        />
        <line class="cls-9" x1="181.19" y1="87.86" x2="181.19" y2="52.24" />
        <polygon
          class="cls-10"
          points="182.04 86.86 181.19 87.71 180.34 86.86 180.34 87.58 181.19 88.43 182.04 87.58 182.04 86.86"
        />
        <polygon
          class="cls-10"
          points="182.04 53.24 181.19 52.39 180.34 53.24 180.34 52.52 181.19 51.67 182.04 52.52 182.04 53.24"
        />
        <line class="cls-9" x1="423.77" y1="87.95" x2="423.77" y2="53.34" />
        <polygon
          class="cls-10"
          points="424.61 86.96 423.77 87.81 422.92 86.96 422.92 87.68 423.77 88.53 424.61 87.68 424.61 86.96"
        />
        <polygon
          class="cls-10"
          points="424.61 54.33 423.77 53.49 422.92 54.33 422.92 53.61 423.77 52.77 424.61 53.61 424.61 54.33"
        />
        <rect class="cls-10" x="177.61" y="50.49" width="6.69" height="0.46" />
        <rect class="cls-10" x="177.61" y="89.49" width="6.69" height="0.46" />
        <rect class="cls-10" x="328.61" y="88.49" width="6.69" height="0.46" />
        <rect class="cls-10" x="328.61" y="0.49" width="6.69" height="0.46" />
        <rect class="cls-10" x="420.61" y="88.49" width="6.69" height="0.46" />
        <rect class="cls-10" x="419.61" y="51.49" width="6.69" height="0.46" />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkMaxCut">
          <v-col style="align-self: center"> {{ selectedLanguage['platformTunnel_component_text3'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.max_cut"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkMinStrucHeightDown">
          <v-col style="align-self: center"> {{ selectedLanguage['platformTunnel_component_text4'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.min_struc_height_down
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #870116;
}
.cls-2 {
  fill: #875116;
}
.cls-3 {
  fill: #ecffc9;
}
.cls-4,
.cls-7,
.cls-8,
.cls-9 {
  fill: none;
}
.cls-4 {
  stroke: #666;
  stroke-width: 7px;
}
.cls-4,
.cls-7,
.cls-9 {
  stroke-miterlimit: 10;
}
.cls-5 {
  font-size: 12px;
  font-family: MyriadPro-Regular, Myriad Pro;
}
.cls-10,
.cls-5 {
  fill: #fff;
}
.cls-6 {
  letter-spacing: -0.05em;
}
.cls-7 {
  stroke: #000;
}
.cls-9 {
  stroke: #fff;
  stroke-width: 0.5px;
}
</style>