<template>
  <!-- NOTIFICATION MENU -->
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn text dark v-bind="attrs" v-on="on"><v-icon>mdi-layers-triple-outline</v-icon>Layer</v-btn> -->
      <v-btn style="align-self: center;" v-if="status.notification.length==0" text v-bind="attrs" v-on="on">
        <v-icon>mdi-bell-circle-outline</v-icon>
      </v-btn>
      <v-btn style="align-self: center;" v-else text v-bind="attrs" v-on="on">
        <v-badge color="green" :content="status.notification.length" overlap>
          <v-icon>mdi-bell-circle-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list dense style="overflow: auto; max-height: 90vh; margin-top: 4vh;">
      <v-list-item v-for="(item, i) in status.notification" :key="i">
        <v-list-item-content v-if="!item.read">
          <v-list-item-title v-text="item.message"/>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-subtitle v-text="item.message"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'NotificationMenu',
    computed: {
      status () {
        return this.$store.getters.status(this.$route.params.id)
      }
    }
  }
</script>