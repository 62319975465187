<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsEarthSVG3Title">
        <strong> {{ selectedLanguage['earthworkCut1_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 466.16 207.23" id="ValuesParamsEarthSVG3">
      <title>cut_1lane</title>
      <g id="Terreno">
        <path
          class="cls-1"
          d="M440.92,4.31c-40.5,7.5-40.53,7.73-40.53,7.73l-63.67,66.3-46.14-3L202.82,177.64H113.05L28.3,75.33C26.82,73.54,1,86.19.4,87.53c-1.54,3.36,0,11.13,0,14.77v102H465.88V-2.9"
          transform="translate(0.28 2.9)"
        />
      </g>
      <g id="Corte">
        <path
          class="cls-2"
          d="M28.3,75.33l84.75,102.31h89.77L290.58,75.33l46.14,3L400.39,12s-158,12.26-210,21.56C149.22,41,28.3,75.33,28.3,75.33Z"
          transform="translate(0.28 2.9)"
        />
      </g>
      <g id="Lastro">
        <polygon
          class="cls-3"
          points="203.67 181.11 186.09 181.07 129.37 181.07 112.91 181.11 121.69 170.4 121.89 170.21 193.9 170.1 193.9 170.1 203.67 181.11"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-4" x="137.06" y="170.21" width="42.06" height="3.93" />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-5"
          points="175.59 168.84 175.59 170.21 171.06 170.21 171.06 168.84 172.71 168.84 172.71 164.96 172.1 164.96 172.1 162.52 174.54 162.52 174.54 164.96 173.93 164.96 173.93 168.84 175.59 168.84"
        />
        <polygon
          class="cls-5"
          points="145.28 168.84 145.28 170.21 140.75 170.21 140.75 168.84 142.41 168.84 142.41 164.96 141.8 164.96 141.8 162.52 144.24 162.52 144.24 164.96 143.63 164.96 143.63 168.84 145.28 168.84"
        />
      </g>
      <g id="Medidas">
        <text class="cls-6" transform="translate(353.29 141.55)">{{ currentCalc.values_params.earthwork.cut_height }} m</text>
        <text class="cls-6" transform="translate(303.13 107.63)">{{ currentCalc.values_params.earthwork.cut_enbankment_width }} m</text>
        <text class="cls-6" transform="translate(63.57 173.63)">{{ Math.round(
              (1 / currentCalc.values_params.earthwork.cut_factor) * 10
            ) / 10 }}</text>
        <text class="cls-6" transform="translate(29.39 151.97)">1</text>
        <rect x="292.11" y="69.44" width="45.27" height="0.43" />
        <polygon
          points="336.53 70.38 337.25 69.65 336.53 68.93 337.14 68.93 337.86 69.65 337.14 70.38 336.53 70.38"
        />
        <path
          class="cls-7"
          d="M56.77,153.67V127.16l23.15,26.4Z"
          transform="translate(0.28 2.9)"
        />
        <rect class="cls-8" x="337.64" y="87.99" width="0.43" height="6.18" />
        <rect class="cls-8" x="291.43" y="87.99" width="0.43" height="6.18" />
        <rect class="cls-8" x="343.7" y="184.18" width="6.18" height="0.43" />
        <rect class="cls-8" x="343.7" y="80.88" width="6.18" height="0.43" />
        <text class="cls-9" transform="translate(303.48 64.99)">{{ currentCalc.values_params.earthwork.cut_enbankment_slope }} %</text>
        <line class="cls-10" x1="293.47" y1="90.61" x2="336.33" y2="90.61" />
        <polygon
          class="cls-8"
          points="294.47 91.45 293.62 90.61 294.47 89.76 293.75 89.76 292.9 90.61 293.75 91.45 294.47 91.45"
        />
        <polygon
          class="cls-8"
          points="335.34 91.45 336.18 90.61 335.34 89.76 336.06 89.76 336.9 90.61 336.06 91.45 335.34 91.45"
        />
        <line class="cls-10" x1="347.4" y1="182.53" x2="347.4" y2="82.68" />
        <polygon
          class="cls-8"
          points="348.25 181.54 347.4 182.39 346.56 181.54 346.56 182.26 347.4 183.1 348.25 182.26 348.25 181.54"
        />
        <polygon
          class="cls-8"
          points="348.25 83.67 347.4 82.82 346.56 83.67 346.56 82.95 347.4 82.11 348.25 82.95 348.25 83.67"
        />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkCutHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.cut_height"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.cut_factor"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text7'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutEnbankmentSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text8'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.cut_enbankment_slope
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutEnbankmentWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text9'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.cut_enbankment_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkSecondCatRate">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text10'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.second_cat_rate
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text11'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkThirdCatRate">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text12'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.third_cat_rate
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text11'] }} </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
  
<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-2 {
  fill: #ff9100;
  opacity: 0.29;
}
.cls-3 {
  fill: #ccc;
}
.cls-4 {
  fill: #666;
}
.cls-5 {
  fill: #3d3d3d;
}
.cls-6,
.cls-9 {
  font-size: 18px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-6,
.cls-8 {
  fill: #fff;
}
.cls-10,
.cls-7 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.cls-10 {
  stroke-width: 0.5px;
}
</style>