<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformSVG3Title">
        <strong> {{ selectedLanguage['platformSleepers_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 446.88 138.92" id="ValuesParamsPlatformSVG3">
      <title>{{ selectedLanguage['platformSleepers_component_text1'] }}</title>
      <g id="Vetores">
        <rect
          class="cls-1"
          x="192.13"
          y="44.16"
          width="254.74"
          height="89.44"
        />
        <rect
          class="cls-2"
          x="178.94"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(300.87 -125.29) rotate(90)"
        />
        <rect
          class="cls-2"
          x="203.93"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(325.87 -150.28) rotate(90)"
        />
        <rect
          class="cls-2"
          x="228.92"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(350.86 -175.27) rotate(90)"
        />
        <rect
          class="cls-2"
          x="253.91"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(375.85 -200.26) rotate(90)"
        />
        <rect
          class="cls-2"
          x="278.9"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(400.84 -225.25) rotate(90)"
        />
        <rect
          class="cls-2"
          x="303.89"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(425.83 -250.24) rotate(90)"
        />
        <rect
          class="cls-2"
          x="328.88"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(450.82 -275.23) rotate(90)"
        />
        <rect
          class="cls-2"
          x="354.26"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(476.2 -300.61) rotate(90)"
        />
        <rect
          class="cls-2"
          x="379.26"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(501.19 -325.61) rotate(90)"
        />
        <rect
          class="cls-2"
          x="404.25"
          y="88.62"
          width="71.78"
          height="10.37"
          transform="translate(526.19 -350.6) rotate(90)"
        />
        <rect
          class="cls-3"
          x="192.15"
          y="112.13"
          width="254.72"
          height="7.27"
        />
        <rect class="cls-3" x="192.15" y="60.99" width="254.72" height="7.27" />
        <rect class="cls-2" x="0.25" y="14.38" width="37.51" height="23.87" />
        <rect class="cls-2" x="0.25" y="85.71" width="120.23" height="23.87" />
      </g>
      <g id="Medidas">
        <line class="cls-4" x1="48.5" y1="37.68" x2="48.5" y2="14.95" />
        <polygon
          points="49.35 36.68 48.5 37.53 47.66 36.68 47.66 37.4 48.5 38.25 49.35 37.4 49.35 36.68"
        />
        <polygon
          points="49.35 15.95 48.5 15.1 47.66 15.95 47.66 15.23 48.5 14.38 49.35 15.23 49.35 15.95"
        />
        <line class="cls-4" x1="54.87" y1="14.38" x2="42.47" y2="14.38" />
        <line class="cls-4" x1="54.54" y1="38.25" x2="42.14" y2="38.25" />
        <line class="cls-4" x1="0.82" y1="47.53" x2="37.19" y2="47.53" />
        <polygon
          points="1.82 48.38 0.97 47.53 1.82 46.68 1.1 46.68 0.25 47.53 1.1 48.38 1.82 48.38"
        />
        <polygon
          points="36.19 48.38 37.04 47.53 36.19 46.68 36.91 46.68 37.76 47.53 36.91 48.38 36.19 48.38"
        />
        <line class="cls-4" x1="0.25" y1="41.33" x2="0.25" y2="53.73" />
        <line class="cls-4" x1="37.76" y1="41.33" x2="37.76" y2="53.73" />
        <line class="cls-4" x1="0.82" y1="119.68" x2="119.91" y2="119.68" />
        <polygon
          points="1.82 120.52 0.97 119.68 1.82 118.83 1.1 118.83 0.25 119.68 1.1 120.52 1.82 120.52"
        />
        <polygon
          points="118.92 120.52 119.76 119.68 118.92 118.83 119.63 118.83 120.48 119.68 119.63 120.52 118.92 120.52"
        />
        <line class="cls-4" x1="0.25" y1="113.48" x2="0.25" y2="125.88" />
        <line class="cls-4" x1="120.48" y1="113.48" x2="120.48" y2="125.88" />
        <line class="cls-4" x1="212.83" y1="24.43" x2="226.31" y2="24.43" />
        <polygon
          points="213.83 25.28 212.98 24.43 213.83 23.58 213.11 23.58 212.26 24.43 213.11 25.28 213.83 25.28"
        />
        <polygon
          points="225.31 25.28 226.16 24.43 225.31 23.58 226.03 23.58 226.88 24.43 226.03 25.28 225.31 25.28"
        />
        <line class="cls-4" x1="212.26" y1="18.23" x2="212.26" y2="30.63" />
        <line class="cls-4" x1="226.88" y1="18.23" x2="226.88" y2="30.63" />
        <text class="cls-5" transform="translate(201.07 11.72)">
          {{
            currentCalc.values_params.superstructure.space_between_sleepers
          }}
          m
        </text>
        <text class="cls-5" transform="translate(58.34 29.61)">
          {{ currentCalc.values_params.superstructure.sleeper_height }} m
        </text>
        <text class="cls-5" transform="translate(6.94 66.72)">
          {{ currentCalc.values_params.superstructure.sleeper_width }} m
        </text>
        <text class="cls-5" transform="translate(42.64 135.42)">
          {{ currentCalc.values_params.superstructure.sleeper_length }} m
        </text>
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsSuperstructureSleeperHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['platformSleepers_component_text2'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.superstructure.sleeper_height
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureSleeperWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platformSleepers_component_text3'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.superstructure.sleeper_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureSleeperLength">
          <v-col style="align-self: center"> {{ selectedLanguage['platformSleepers_component_text4'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.superstructure.sleeper_length
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureSpaceBetweenSleepers">
          <v-col style="align-self: center"> {{ selectedLanguage['platformSleepers_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.superstructure.space_between_sleepers
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2 {
  fill: #666;
}
.cls-3 {
  fill: #3d3d3d;
}
.cls-4 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}
.cls-5 {
  font-size: 14px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>