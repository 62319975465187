<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsEarthSVG3Title">
        <strong> {{ selectedLanguage['earthworkCut1_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 596.16 207.23" id="ValuesParamsPlatformSVG1Platform2">
      <title>cut_2lanes</title>
      <g id="Terreno">
        <path
          class="cls-1"
          d="M570.92,4.31c-40.5,7.5-40.53,7.73-40.53,7.73l-63.67,66.3-46.14-3L332.82,177.64H113.05L28.3,75.33C26.82,73.54,1,86.19.4,87.53c-1.54,3.36,0,11.13,0,14.77v102H595.88V-2.9"
          transform="translate(0.28 2.9)"
        />
      </g>
      <g id="Corte">
        <path
          class="cls-2"
          d="M28.3,75.33l84.32,102.88,220.2-.57L420.58,75.33l46.14,3L530.39,12s-158,12.26-210,21.56C279.22,41,28.3,75.33,28.3,75.33Z"
          transform="translate(0.28 2.9)"
        />
      </g>
      <g id="Lastro">
        <polygon
          class="cls-3"
          points="332.67 181.11 316.08 181.07 259.37 181.07 242.91 181.11 251.69 170.4 251.9 170.21 323.13 170.21 323.33 170.4 332.67 181.11"
        />
        <polygon
          class="cls-3"
          points="204.88 180.83 188.29 180.79 131.57 180.79 115.12 180.83 123.9 170.12 124.11 169.93 195.34 169.93 195.54 170.12 204.88 180.83"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-4" x="139.27" y="169.93" width="42.06" height="3.93" />
        <rect class="cls-4" x="267.06" y="170.21" width="42.06" height="3.93" />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-5"
          points="305.59 168.84 305.59 170.21 301.06 170.21 301.06 168.84 302.71 168.84 302.71 164.96 302.1 164.96 302.1 162.52 304.54 162.52 304.54 164.96 303.93 164.96 303.93 168.84 305.59 168.84"
        />
        <polygon
          class="cls-5"
          points="177.8 168.56 177.8 169.93 173.27 169.93 173.27 168.56 174.92 168.56 174.92 164.68 174.31 164.68 174.31 162.24 176.75 162.24 176.75 164.68 176.14 164.68 176.14 168.56 177.8 168.56"
        />
        <polygon
          class="cls-5"
          points="147.49 168.56 147.49 169.93 142.96 169.93 142.96 168.56 144.62 168.56 144.62 164.68 144.01 164.68 144.01 162.24 146.45 162.24 146.45 164.68 145.84 164.68 145.84 168.56 147.49 168.56"
        />
        <polygon
          class="cls-5"
          points="275.28 168.84 275.28 170.21 270.75 170.21 270.75 168.84 272.41 168.84 272.41 164.96 271.8 164.96 271.8 162.52 274.24 162.52 274.24 164.96 273.63 164.96 273.63 168.84 275.28 168.84"
        />
      </g>
      <g id="Medidas">
        <text class="cls-6" transform="translate(483.29 141.55)">{{ currentCalc.values_params.earthwork.cut_height }} m</text>
        <text class="cls-6" transform="translate(433.13 107.63)">{{ currentCalc.values_params.earthwork.cut_enbankment_width }} m</text>
        <text class="cls-6" transform="translate(63.57 173.63)">{{ Math.round(
            (1 / currentCalc.values_params.earthwork.cut_factor) * 10
          ) / 10 }}</text>
        <text class="cls-6" transform="translate(29.39 151.97)">1</text>
        <rect x="422.11" y="69.44" width="45.27" height="0.43" />
        <polygon
          points="466.53 70.38 467.25 69.65 466.53 68.93 467.14 68.93 467.86 69.65 467.14 70.38 466.53 70.38"
        />
        <path
          class="cls-7"
          d="M56.77,153.67V127.16l23.15,26.4Z"
          transform="translate(0.28 2.9)"
        />
        <rect class="cls-8" x="467.64" y="87.99" width="0.43" height="6.18" />
        <rect class="cls-8" x="421.43" y="87.99" width="0.43" height="6.18" />
        <rect class="cls-8" x="473.7" y="184.18" width="6.18" height="0.43" />
        <rect class="cls-8" x="473.7" y="80.88" width="6.18" height="0.43" />
        <text class="cls-9" transform="translate(433.48 64.99)">{{ Math.round(
              (1 / currentCalc.values_params.earthwork.cut_enbankment_slope) * 10
            ) / 10 }} %</text>
        <line class="cls-10" x1="423.47" y1="90.61" x2="466.33" y2="90.61" />
        <polygon
          class="cls-8"
          points="424.47 91.45 423.62 90.61 424.47 89.76 423.75 89.76 422.9 90.61 423.75 91.45 424.47 91.45"
        />
        <polygon
          class="cls-8"
          points="465.34 91.45 466.18 90.61 465.34 89.76 466.06 89.76 466.9 90.61 466.06 91.45 465.34 91.45"
        />
        <line class="cls-10" x1="477.4" y1="182.53" x2="477.4" y2="82.68" />
        <polygon
          class="cls-8"
          points="478.25 181.54 477.4 182.39 476.56 181.54 476.56 182.26 477.4 183.1 478.25 182.26 478.25 181.54"
        />
        <polygon
          class="cls-8"
          points="478.25 83.67 477.4 82.82 476.56 83.67 476.56 82.95 477.4 82.11 478.25 82.95 478.25 83.67"
        />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkCutHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.cut_height"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.earthwork.cut_factor"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text7'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutEnbankmentSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text8'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.cut_enbankment_slope
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutEnbankmentWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text9'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.cut_enbankment_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkSecondCatRate">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text10'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.second_cat_rate
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text11'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkThirdCatRate">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text12'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.earthwork.third_cat_rate
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text11'] }} </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
  
<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-2 {
  fill: #ff9100;
  opacity: 0.29;
}
.cls-3 {
  fill: #ccc;
}
.cls-4 {
  fill: #666;
}
.cls-5 {
  fill: #3d3d3d;
}
.cls-6,
.cls-9 {
  font-size: 18px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-6,
.cls-8 {
  fill: #fff;
}
.cls-10,
.cls-7 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.cls-10 {
  stroke-width: 0.5px;
}
</style>