<template>
  <div>
    <v-app-bar app clipped-left :color="color" dense dark style="overflow-x: auto;">
      <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
      <v-btn :loading="loading2" text @click="clickCancelExit()">
        <v-icon>mdi-chevron-left</v-icon>{{ selectedLanguage['adjustParameters_component_text1'] }}
      </v-btn>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="curves">{{ selectedLanguage['adjustParameters_component_text2'] }}</v-tab>
        <v-tab key="platform">{{ selectedLanguage['adjustParameters_component_text3'] }}</v-tab>
        <v-tab key="earthwork">{{ selectedLanguage['adjustParameters_component_text4'] }}</v-tab>
      </v-tabs>
      <v-btn :loading="loading1" text @click="clickSaveExit()">
        {{ selectedLanguage['adjustPrices_component_text2'] }}<v-icon>mdi-chevron-right</v-icon> 
      </v-btn>
    </v-app-bar>
    <v-card v-if="currentCalc" class="elevation-0">
      <v-tabs-items v-model="tab">
        <v-tab-item key="curves">
            <Curves/>
        </v-tab-item>
        <v-tab-item key="platform">
          <v-card-text>
            <v-row>
              <v-col style="align-self: center;">
                <v-select outlined v-model="currentCalc.values_params.platform.lanes" :items="[1,2]" item-text="name" item-value="id" :label="selectedLanguage['railway_params_lable_1']"/>
              </v-col>
            </v-row>
            <PlatformAquisition1Lane v-if="currentCalc.values_params.platform.lanes==1"/>
            <PlatformAquisition2Lanes v-else/>
            <br><v-divider/><br>
            <v-row v-if="currentCalc.values_params.platform.lanes==1">
              <v-col></v-col>
              <v-col cols="12">
                <Platform1Lane />
              </v-col>
              <v-col></v-col>
            </v-row>
            <v-row v-else>
              <v-col></v-col>
              <v-col cols="12">
                <Platform2Lanes />
              </v-col>
              <v-col></v-col>
            </v-row>
            <br><v-divider/><br>
            <PlatformSleepers/>
            <br><v-divider/><br>
            <PlatformBridge/>
            <br>
            <v-col col="12">
              <v-spacer/>
              <PlatformBridge1Lane v-if="currentCalc.values_params.platform.lanes==1"/>
              <PlatformBridge2Lanes v-else/>
              <v-spacer/>
            </v-col>
            <br><v-divider/><br>
            <PlatformTunnel/>
            <br><br>
            <v-col col="12">
              <v-spacer/>
              <PlatformTunnel1Lane v-if="currentCalc.values_params.platform.lanes==1"/>
              <PlatformTunnel2Lanes v-else/>
              <v-spacer/>
            </v-col>
          </v-card-text>
        </v-tab-item>
        <v-tab-item key="earthwork">
          <v-card-text>
            <EarthworkTransport/>
            <br><v-divider/><br>
            <EarthworkCut1Lane v-if="currentCalc.values_params.platform.lanes==1"/>
            <EarthworkCut2Lanes v-else/>
            <br><v-divider/><br>
            <EarthworkFill1Lane v-if="currentCalc.values_params.platform.lanes==1"/>
            <EarthworkFill2Lanes v-else/>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
  
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Curves from './params/Curves.vue'
  import PlatformAquisition1Lane from './params/PlatformAquisition1Lane.vue'
  import PlatformAquisition2Lanes from './params/PlatformAquisition2Lanes.vue'
  import Platform1Lane from './params/Platform1Lane.vue'
  import Platform2Lanes from './params/Platform2Lanes.vue'
  import PlatformBridge from './params/PlatformBridge.vue'
  import PlatformBridge1Lane from './params/PlatformBridge1Lane.vue'
  import PlatformBridge2Lanes from './params/PlatformBridge2Lanes.vue'
  import PlatformTunnel from './params/PlatformTunnel.vue'
  import PlatformTunnel1Lane from './params/PlatformTunnel1Lane.vue'
  import PlatformTunnel2Lanes from './params/PlatformTunnel2Lanes.vue'
  import PlatformSleepers from './params/PlatformSleepers.vue'
  import EarthworkTransport from './params/EarthworkTransport.vue';
  import EarthworkCut1Lane from './params/EarthworkCut1Lane.vue';
  import EarthworkCut2Lanes from './params/EarthworkCut2Lanes.vue';
  import EarthworkFill1Lane from './params/EarthworkFill1Lane.vue';
  import EarthworkFill2Lanes from './params/EarthworkFill2Lanes.vue';

  import { portuguese } from "../../../languages/pt-br";
  import { english } from "../../../languages/en";

  export default {
    name: 'AdjustParameters',
    components: {
    Curves,
    PlatformSleepers,
    EarthworkTransport,
    EarthworkCut1Lane,
    EarthworkCut2Lanes,
    EarthworkFill1Lane,
    EarthworkFill2Lanes,
    Platform1Lane,
    Platform2Lanes,
    PlatformAquisition1Lane,
    PlatformAquisition2Lanes,
    PlatformBridge,
    PlatformBridge1Lane,
    PlatformBridge2Lanes,
    PlatformTunnel,
    PlatformTunnel1Lane,
    PlatformTunnel2Lanes,
    },
    computed:{
      steps () {
        return this.$store.getters.steps('railway')
      },
      status () {
        return this.$store.getters.status(this.$route.params.id)
      },
      ...mapGetters(['currentCalc','balanceFormatted','profile']),
      selectedLanguage() {
      if (this.profile['language'] === 'en') {
        return english;
      } else {
        return portuguese;
      }
    },
    },
    data() {
      return {
        logoIconUrl: '/img/icons/logo_icon.webp',
        tab: 'curves',
        color: '#0A7373',
        loading1: false,
        loading2: false,
      };
    },
    mounted: async function (){
    },
    methods: {
      async goStep(step){
        await this.changeStatus({calcId: this.$route.params.id, payload: {'status': 'Init','step_name': this.steps[step-1], 'step': step, 'notification': this.status.notification}})
      },
      ...mapActions(['updateParams','changeStatus']),
      async clickSaveExit() {
        this.loading1 = true
        await this.updateParams()
        await this.goStep(4)
        this.loading1 = false
      },
      async clickCancelExit() {
        this.loading2 = true
        await this.goStep(2)
        this.loading2 = false
      }
    },
  }
</script>