<template>
  <div v-if="status && currentCalc">
    <v-app-bar v-if=" currentCalc.deprecated == false && status.step==1 || status.step==6 || status.step==7 && status.status=='Done' || status.step==7 && status.status=='Calculating' || status.status == 'Error' && status.step!=8" app clipped-left :color="color" dense dark>
      <v-btn v-if="status.step==6 && status.status=='Init'" :loading="loading2" text @click="clickCancelExit()">
        <v-icon>mdi-chevron-left</v-icon>{{ selectedLanguage['adjustPrices_component_text3'] }}
      </v-btn>
      <v-spacer />
      <v-toolbar-title>
          <a href="/"><v-img class="d-none d-sm-block" :src="logoUrl" width="180px" alt="wwcalc"/></a>
          <a href="/"><v-img class="d-flex d-sm-none" :src="logoUrl" width="90px" alt="wwcalc"/></a>
      </v-toolbar-title>
      <v-spacer />
      <v-btn text @click="redirectFunc()">₡ {{this.balanceFormatted}}</v-btn>
      <NotificationMenu/>
      <v-btn v-if="status.step==1" :loading="loading1" text @click="clickSaveExitStep1()">
        {{ selectedLanguage['roadway_component_text1'] }}<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn v-if="status.step==6 && status.status=='Init' && this.currentCalc.quantity == 0" text disabled>
        {{ selectedLanguage['roadway_component_text1'] }}<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn v-else-if="status.step==6 && status.status=='Init' && currentCalc && this.currentCalc.quantity >= 5000" text disabled>
        {{ selectedLanguage['roadway_component_text1'] }}<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn v-else-if="status.step==6 && status.status=='Init' && currentCalc && this.currentCalc.quantity < 5000" text @click="setBasicDialig()">
        {{ selectedLanguage['roadway_component_text1'] }}<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn v-else-if="(status.step==6 || status.step==7) && status.status=='Done'" text @click="nextStep()">
        {{ selectedLanguage['roadway_component_text1'] }}<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app :mini-variant="$vuetify.breakpoint.mdAndDown" :expand-on-hover="$vuetify.breakpoint.smAndDown" permanent clipped width="300" :color="color" dark>
      <v-list dense nav class="py-0">
        <v-list-item disabled v-for="(step,idx) in steps" :key="idx" @click="$router.push({ name: 'new_railway' })">
          <v-list-item-icon v-if="status.step<idx+1">{{idx+1}}</v-list-item-icon>
          <v-list-item-icon v-else-if="status.step==idx+1" style="color:white;">{{idx+1}}</v-list-item-icon>
          <v-list-item-icon v-else><v-icon disabled>mdi-check</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="status.step==idx+1" style="color:white;">{{step}}</v-list-item-title>
            <v-list-item-title v-else>{{step}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div v-if="status.status == 'Error' && status.step!=8">
      <v-dialog v-model="calcError" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedLanguage['roadway_component_text2'] }}
        </v-card-title>
        <v-card-text>{{ selectedLanguage['roadway_component_text3'] }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :color="color" :loading="loading1" dark variant="text" @click="archiveCalc(currentCalc.id)">{{ selectedLanguage['roadway_component_text4'] }} <v-icon>mdi-delete-forever-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <div v-else-if="currentCalc.deprecated == true && status.step!=8">
      <UpdateCalcParams/>
    </div>
    <div v-else>
      <div class="overlay" v-if="loadingCalc">
      <v-progress-circular indeterminate :color="color"></v-progress-circular>
    </div>
    <div v-else-if="currentCalc">
      <v-alert v-if="errorMessage" dense border="top" color="error" dark>
        {{ errorMessage }}
      </v-alert>
      <div v-if="status.step==1">
        <v-container>
          <form>
            <v-text-field v-model="currentCalc.title" :label="selectedLanguage['roadway_component_text5']" required/>
            <v-text-field v-model="currentCalc.description" :label="selectedLanguage['roadway_component_text6']"/>
            <v-text-field v-model="currentCalc.company" :label="selectedLanguage['roadway_component_text7']"/>
          </form>
        </v-container>
      </div>
      <div v-else-if="status.step==2">
        <AdjustPrices/>
      </div>
      <div v-else-if="status.step==3">
        <AdjustParameters/>
      </div>
      <div v-else-if="status.step==4">
        <Map key="mapTrack" :srid="srid" drawType="Point" :initZoom="false" :inputPointEnable="true" :inputUploadFileEnable="false" :inputSearchEnable="false"></Map>
      </div>
      <div v-else-if="status.step==5">
        <Map key="mapLimit" drawType="Polygon" :srid="srid" :layers="[track]" :initZoom="true" :inputPolygonEnable="false" :inputUploadFileEnable="true" :inputSearchEnable="false"></Map>
      </div>
      <div v-else-if="status.step==6" style="display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative;">
        <v-container>
          <div v-if="status.status=='Init' && this.currentCalc.quantity < 5000">
            <v-alert border="top" color="info" dark>
              {{ selectedLanguage['roadway_component_text8'] }}
            </v-alert>
            <v-alert border="top" color="info" dark>
              {{ selectedLanguage['roadway_component_text9'] }} ₡{{ (-currentCalc.comparative_price).toLocaleString() }} {{ selectedLanguage['roadway_component_text10'] }} {{ selectedLanguage['area_is_text'] }} {{ this.areasizekm2 }} km²
            </v-alert>
            <v-alert border="top" color="warning" dark>
              {{ selectedLanguage['roadway_component_text11'] }}
            </v-alert>
          </div>
          <div v-else-if="status.status=='Init' && this.currentCalc.quantity >= 5000">
            <v-alert border="top" color="warning" dark>
              {{ selectedLanguage['area_is_way_too_big_warning_text'] }} {{ selectedLanguage['area_is_text'] }} {{ this.areasizekm2 }} km²
            </v-alert>
          </div>
          <div step="6" v-else-if="status.status=='Calculating'">
            <v-col v-if="['Creating report parameters','Export track and limit','Update Raster Budget','Extracting vectors'].includes(status.step_name)">
              <v-spacer/>
              <v-row>
                <v-spacer/>
                <img src="/img/characterize.gif" alt="Loading" style="max-height: 80vh; "/>
                <v-spacer/>
              </v-row>
              <br><br>
              <v-row>
                <v-spacer/>
                <v-alert dense border="left" color="info" dark>
                  <v-btn :loading='true' :color="color" disabled text></v-btn>{{ selectedLanguage['roadway_component_text12'] }}
                </v-alert>
                <v-spacer/>
              </v-row>
            </v-col>

            <v-col v-else-if="status.step_name == 'Cost map'">
              <v-spacer/>
              <v-row>
                <v-spacer/>
                <img src="/img/characterize.gif" alt="Loading" style="max-height: 50vh; "/>
                <v-spacer/>
              </v-row>
              <br><br>
              <v-row>
                <v-spacer/>
                <v-alert dense border="left" color="info" dark>
                  <v-btn :loading='true' :color="color" disabled text></v-btn>{{ selectedLanguage['status_text_1'] }}
                </v-alert>
                <v-spacer/>
              </v-row>
            </v-col>

            <v-col v-else>
              <v-spacer/>
              <v-row>
                <v-spacer/>
                <div style="display: flex; flex-direction: column; width: 80%; margin-top: 40px;">
                  <!-- <div class="areaWarning" v-if="this.area1kkm2 == true && this.closeWarning == false">
                    <p class="removeStyle">{{ selectedLanguage['arealimit1kkm2_warning'] }}{{ this.areasizekm2 }} km² </p>
                    <div style="margin-left: 20px;">
                      <v-icon style="color: black; font-size: 20px;" @click="closeWarning1kkm2()">mdi mdi-close-box</v-icon>
                    </div>
                  </div> -->
                  <v-alert border="top" color="warning" dark v-if="this.area1kkm2 == true && this.closeWarning == false">
                    {{ selectedLanguage['arealimit1kkm2_warning'] }}{{ this.areasizekm2 }} km²
                  </v-alert>
                  <div v-for="status,indx in alternativeStatus" :key="indx">
                    <div> {{ selectedLanguage['roadway_component_text24'] }} {{ status.number }}</div>
                    <v-progress-linear style="pointer-events: none" v-model.number="status.status" color="light-blue" height="25" striped rounded v-if="status.status != null">
                      <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                      </template>
                    </v-progress-linear>
                    <v-progress-linear style="pointer-events: none" v-model.number="skill" color="light-blue" height="25" striped v-else>
                      <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                      </template>
                    </v-progress-linear>
                  </div>
                </div>
                <v-spacer/>
              </v-row>
            </v-col>
          </div>
          <div v-if="status.status=='Done'" step="6">
            <v-col>
              <v-spacer/>
              <v-row>
                <v-spacer/>
                <v-icon size="125" style="height: 40vh; width: auto;">mdi-check-circle</v-icon>
                <v-spacer/>
              </v-row>
              <v-row>
                <v-spacer/>
                <v-alert border="top" color="info" dark>
                  {{ selectedLanguage['roadway_component_text14'] }}
                </v-alert>    
                <v-spacer/>
              </v-row>
              <v-spacer/>
            </v-col>
          </div>

          <div v-else-if="status.status=='Error'" step="6">
            <v-alert border="top" color="error" dark>
              {{ selectedLanguage['roadway_component_text15'] }}
            </v-alert>
            <v-alert border="top" color="error" dark>
              {{ selectedLanguage['roadway_component_text16'] }}
            </v-alert>
          </div>
        </v-container>
      </div>

      <div v-else-if="status.step==7 && status.status=='Init' || status.status=='HasChanges'">
        <CostMapUpdate @callUpdate="UpdateCostMapCharge()" @callcharge="CostMapCharge()" @callBasic="noChangesCallBasicOnMap()" ref="CostMapUpdateComp"/>
      </div>

      <v-col v-else-if="status.step_name == 'Clip Cost Map' || status.step_name == 'Calculate alternatives'">
        <v-spacer/>
        <v-row>
          <v-spacer/>
          <img src="/img/characterize.gif" alt="Loading" style="max-height: 50vh; "/>
          <v-spacer/>
        </v-row>
        <br><br>
        <v-row>
          <v-spacer/>
          <v-alert dense border="left" color="info" dark>
            <v-btn :loading='true' :color="color" disabled text></v-btn>{{ selectedLanguage['status_text_3'] }}
          </v-alert>
          <v-spacer/>
        </v-row>
      </v-col>

      <div v-else-if="status.step==7 && status.step_name == 'Alignment Path' || status.step==7 && status.step_name == 'Basic Alternative' " style="display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative;">
        <v-col>
          <v-spacer/>
          <v-row>
            <v-spacer/>
            <div style="display: flex; flex-direction: column; width: 80%; margin-top: 40px;">
              <!-- <div class="areaWarning" v-if="this.area1kkm2 == true && this.closeWarning == false">
                <p class="removeStyle">{{ selectedLanguage['arealimit1kkm2_warning'] }}{{ this.areasizekm2 }} km² </p>
                <div style="margin-left: 20px;">
                  <v-icon style="color: black; font-size: 20px;" @click="closeWarning1kkm2()">mdi mdi-close-box</v-icon>
                </div>
              </div> -->
              <v-alert border="top" color="warning" dark v-if="this.area1kkm2 == true && this.closeWarning == false">
                {{ selectedLanguage['arealimit1kkm2_warning'] }}{{ this.areasizekm2 }} km²
              </v-alert>
              <div v-for="status,indx in alternativeStatus" :key="indx">
                <div>{{ selectedLanguage['roadway_component_text25'] }} {{ status.number }}</div>
                <v-progress-linear style="pointer-events: none" v-model.number="status.status" color="light-blue" height="25" striped rounded v-if="status.status != null">
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
                <v-progress-linear style="pointer-events: none" v-model.number="skill" color="light-blue" height="25" striped v-else>
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </div>
            </div>
            <v-spacer/>
          </v-row>
          <br><br>
          <v-row>
            <v-spacer/>
            <v-spacer/>
          </v-row>
        </v-col>
      </div>

      <v-col v-else-if="status.step==7 && status.status=='Done'">
        <v-spacer/>
        <v-row>
          <v-spacer/>
          <img src="/img/characterize.gif" alt="Loading" style="max-height: 50vh; "/>
          <v-spacer/>
        </v-row>
        <br><br>
        <v-row>
          <v-spacer/>
          <v-alert dense border="left" color="info" dark>
            <v-btn :loading='true' :color="color" disabled text></v-btn>{{ selectedLanguage['status_text_4'] }}
          </v-alert>
          <v-spacer/>
        </v-row>
      </v-col>

      <div v-else-if="status.step==8">
        <Detail/>
      </div>

    </div>

    <v-dialog v-if="chargeCostMap === true" v-model="chargeCostMap" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedLanguage['roadway_component_text20'] }}
        </v-card-title>
        <v-card-text>{{ selectedLanguage['roadway_component_text21'] }} ₡{{ (-currentCalc.characterization_price).toLocaleString() }}</v-card-text>
        <v-alert v-if="errorMessage" dense border="top" color="error" dark>
          {{ errorMessage }}
        </v-alert>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="green-darken-1" variant="text" @click="chargeCostMap=false">{{ selectedLanguage['roadway_component_text22'] }}</v-btn>
          <v-btn :color="color" :loading="loading1" dark variant="text" @click="callBasicOnMap()">{{ selectedLanguage['roadway_component_text23'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="currentCalc" v-model="confirmBasicDialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedLanguage['roadway_component_text20'] }}
        </v-card-title>
        <v-card-text>{{ selectedLanguage['roadway_component_text21'] }} ₡{{ (-currentCalc.comparative_price).toLocaleString() }}</v-card-text>
        <v-alert v-if="errorMessage" dense border="top" color="error" dark>
          {{ errorMessage }}
        </v-alert>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="green-darken-1" variant="text" @click="confirmBasicDialog=false">{{ selectedLanguage['roadway_component_text22'] }}</v-btn>
          <v-btn :color="color" :loading="loading1" dark variant="text" @click="clickBasicCalc()">{{ selectedLanguage['roadway_component_text23'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </div>
</template>

<script>
  import AdjustPrices from './AdjustPrices.vue'
  import AdjustParameters from './AdjustParameters.vue'
  import Map from '../Map.vue'
  import CostMapUpdate from '../CostMapUpdate.vue'
  import Detail from '../Detail.vue'
  import { mapGetters, mapActions } from 'vuex'
  import NotificationMenu from '../../../components/NotificationMenu.vue'
  import store from '../../../store'
  import UpdateCalcParams from './UpdateCalcParams'
  import { portuguese } from '../../../languages/pt-br'
  import { english } from '../../../languages/en'

  export default {
    name: 'RailwayFlow',
    components:{ UpdateCalcParams,AdjustPrices,AdjustParameters,Map,CostMapUpdate,Detail,NotificationMenu},    
    computed: {
      steps () {
        return this.selectedLanguage['allSteps_array_obj']['railway']
      },
      status () {
        return this.$store.getters.status(this.$route.params.id)
      },
      alternativeStatus(){
        return this.$store.getters.alternativesStatus
      },
      configDefaults () {
        return this.$store.getters.configDefaultsByType('railway')
      },
      ...mapGetters(['profile','vectorsType','validationStoragedProps','balanceFormatted','limit','track','currentCalc', 'statusHome', 'intervalId', 'alternativesDetail','alternatives', 'alternativesStatus']),
      srid () {
        try{
          if(this.currentCalc.srid){
            window.localStorage.setItem('CurrentSRID', 'EPSG:'+this.currentCalc.srid)
            return this.currentCalc.srid
          } else {
            return 3857
          }
        } catch (err){
          return 3857
        }
      },
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    mounted: async function(){
      await this.loadCalc()
      var intervalId = JSON.parse(localStorage.getItem('vuex'))['auth']['intervalId']
      window.clearInterval(intervalId)
      this.intervalIdFunc(
        window.setInterval(() => {
          this.getStatuses()
          this.getAlternatives(this.$route.params.id)
        }, 10000)
      )
      if(this.status.step == 6 && this.status.status == 'Init'){
          await this.calculateArea()
        }
    },
    data: () => ({
      skill: "0",
      cloudPasswordInput: false,
      cloudPassword: undefined,
      logoUrl: '/img/logo.png',
      permanent: false,
      drawer: true,
      loadingCalc: true,
      comparativePdf: true,
      loading1: false,
      loading2: false,
      trackChoice: undefined,
      limitChoice: undefined,
      calcId: undefined,
      color: '#0A7373',
      baseLayer: undefined,
      reportAlternativeNumber: undefined,
      number: undefined,
      confirmBasicDialog: false,
      errorMessage: undefined,
      chargeCostMap: false,
      updateChargeCostMap: false,
      calcError: true,
      storeErrorMessage: undefined,
      area1kkm2:false,
      areasizekm2: undefined,
      closeWarning: false,
    }),
    methods: {
      closeWarning1kkm2(){
        this.closeWarning = true
      },
      async calculateArea() {
        if(this.currentCalc.quantity > 1000){
          this.area1kkm2 = true
        } else {
          this.area1kkm2 = false
        }
        this.areasizekm2 = await this.formatNumber(this.currentCalc.quantity)
      },
      formatNumber(number) {
        return number.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      setBasicDialig(){
        this.loading1 = false
        this.confirmBasicDialog = true
      },
      async CheckDefautl(){
        const resp = await this.getUserDefaults({ type_id : this.currentCalc.type_id , calc_hash:this.currentCalc.id })
        console.log('oq chegou: ', resp)
      },
      redirectFunc(){
        if(process.env.VUE_APP_VAR_SELECT== 'prd'){
          this.$router.push({ name: 'billingMercadoPago' })
        } else {
          this.$router.push({ name: 'billing' })
        }
      },
      async archiveCalc(calcId){
        this.getMonetize()
        this.storeErrorMessage = undefined
        const res = await this.updateCalc({'id': calcId, 'status': 'i'})
        if(res!=true){
          this.storeErrorMessage = res
        }
        this.$router.push({ name: 'home' })
      },
      CostMapCharge(){
        this.loading1 = false
        this.chargeCostMap = true
      },
      UpdateCostMapCharge(){
        this.updateChargeCostMap = true
      },
      async callBasicOnMap(){
        this.$refs.CostMapUpdateComp.LoadingSwitch()
        this.errorMessage = undefined
        this.chargeCostMap = false
        var msgError = this.selectedLanguage['error_msg_txt_1']
        const getupdateVectorLoop = await this.updateVectorLoop({calcId: this.$route.params.id, payload: store.getters.storagedProps, vectorType: store.getters.vectorsType, validationStoragedProps: store.getters.validationStoragedProps})
        const getchargeCharacterization = await this.chargeCharacterization({calcId: this.$route.params.id})
        let res = await getupdateVectorLoop
        var serviceS = window.localStorage.getItem('service_status')
        if(serviceS == 'unstable'){
          msgError = this.selectedLanguage['error_msg_txt_2']
        }
        if(res!=true && res != 'Cost Map Success'){
          this.errorMessage = msgError
        }
        res = await getchargeCharacterization
        if(res != true && serviceS != 'unstable'){
          this.errorMessage = res
          this.downloadingLayers = false
          await this.changeStatus({calcId: this.$route.params.id, payload: {"status": "Error", "step_name": "Calculate alternatives", "step": 7}})
        } else {
          this.errorMessage = msgError
          this.downloadingLayers = false
          await this.changeStatus({calcId: this.$route.params.id, payload: {"status": "Error", "step_name": "Calculate alternatives", "step": 7}})
        }
        res = await this.getMonetize()
        if(res!=true){
          this.errorMessage = msgError
        }
      },
      async noChangesCallBasicOnMap(){
        this.$refs.CostMapUpdateComp.LoadingSwitch()
        this.errorMessage = undefined
        var msgError = this.selectedLanguage['error_msg_txt_1']
        await this.changeStatus({calcId: this.$route.params.id, payload: {"status": "Calculating", "step_name": "Calculate alternatives", "step": 7}})
        let res = await this.basicCalc(this.$route.params.id)
        var serviceS = window.localStorage.getItem('service_status')
        if(serviceS == 'unstable'){
          msgError = this.selectedLanguage['error_msg_txt_2']
        }
        if(res != true){
          this.errorMessage = msgError
          await this.changeStatus({calcId: this.$route.params.id, payload: {"status": "Error", "step_name": "Calculate alternatives", "step": 7}})
        }
        res = await this.getMonetize()
        if(res!=true){
          this.errorMessage = msgError
        }
        this.chargeCostMap=false
      },
      backToHome() {
        this.$router.push('/')
      },
      async clickSaveExitStep1() {
        this.errorMessage = undefined
        this.loading1 = true
        const payload = {
          id: this.$route.params.id,
          title: this.currentCalc.title,
          description: this.currentCalc.description,
          company: this.currentCalc.company
        }
        if(this.status.step == 5 && this.status.status!="Init"){
          await this.calculateArea()
        }
        let res = await this.updateCalc(payload)
        if(res!=true){
          this.errorMessage = res
          return false
        }
        res = await this.goStep(2)
        if(res!=true){
          this.errorMessage = res
          return false
        }
      },
      async loadCalc(){
        this.errorMessage = undefined
        const getCurrentCalcPromise = await this.getCurrentCalc(this.$route.params.id)
        const getLayersPromise = await this.getLayers(this.$route.params.id)
        await Promise.all([this.getCurrentCalc(this.$route.params.id),this.getLayers(this.$route.params.id)])
        let res = await getCurrentCalcPromise
        if(res!=true){
          this.errorMessage = res
          this.loadingCalc = false
          return false
        }
        res = await getLayersPromise
        if(res!=true){
          this.errorMessage = res
          this.loadingCalc = false
          return false
        }
        this.loadingCalc = false
      },
      ...mapActions(['getUserDefaults','updateVectorLoop','chargeCharacterization','corridorCalc','updateCalc','loginCloud','basicCalc','getLayers','changeStatus','getCurrentCalc','changeLoadAlternative','useCredits','getMonetize','intervalIdFunc','getStatuses', 'getAlternatives']),
      async goStep(step){
        this.errorMessage = undefined
        const res = await this.changeStatus({calcId: this.$route.params.id, payload: {'status': 'Init','step_name': this.steps[step-1], 'step': step, 'notification': this.status.notification}})
        if(res!=true){
          this.errorMessage = res
        }
      },
      async clickBasicCalc(){
        this.errorMessage = undefined
        this.loading1 = true
        var msgError = this.selectedLanguage['error_msg_txt_1']
        let res = await this.useCredits({calcId: this.$route.params.id})
        var serviceS = window.localStorage.getItem('service_status')
        if(serviceS == 'unstable'){
          msgError = this.selectedLanguage['error_msg_txt_2']
        }
        if(res!=true){
          this.errorMessage = msgError
          return false
        }
        res = await this.getMonetize()
        if(res!=true){
          this.errorMessage = msgError
          this.loadingCalc = false
          return false
        }
        await this.corridorCalc(this.$route.params.id)
        const getLayersPromise = await this.getLayers(this.$route.params.id)

        res = await getLayersPromise
        if(res!=true){
          this.errorMessage = msgError
          this.loadingCalc = false
          return false
        }
        this.status.status = 'Calculating'
        this.status.step_name = 'Creating report parameters'
        this.confirmBasicDialog = false
      },
      async clickCancelExit() {
        this.errorMessage = undefined
        this.loading2 = true
        const res = await this.goStep(this.status.step-1)
        if(res!=true){
          this.errorMessage = res
        }
        this.loading2 = false
      },
      async nextStep() {
        this.errorMessage = undefined
        this.loading1 = true
        const res = await this.goStep(this.status.step+1)
        if(res!=true){
          this.errorMessage = res
        }
      }
    },
    watch: {
      status (val,oldVal) {
        if(val.step==6 && oldVal.step==5) {
          this.getCurrentCalc(this.$route.params.id)
        }
        if(val.step==6 && this.confirmBasicDialog && val.status!="Init") {
          this.calculateArea()
          this.confirmBasicDialog = false
        }
        if(val.status == "Error"){
          this.loading1 = false
        }
        if( val.step == 7 || val.step == 6){
          this.calculateArea()
        }
      },
    },
  }
</script>

<style scoped>
  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: #FFFFFF;
  }
  .border {
    border: 2px dashed orange;
  }
  .areaWarning{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px !important;
    font-size: 16px;
    background-color: orange;
    border-radius: 10px;
    font-weight: 400;
  }
  .removeStyle{
    margin: 0 !important;
    padding: 0 !important;
  }
</style>