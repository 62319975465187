export var listValuesParamsPlatform =
    [
    "ValuesParamsPlatformLanes",
    "ValuesParamsPlatformSVG1Title",
    "ValuesParamsPlatformSVG1Platform2",
    "ValuesParamsPlatformSVG4Title",
    "ValuesParamsPlatformSVG3Title",
    "ValuesParamsPlatformSVG6Title",
    "ValuesParamsPlatformTitleSVG2",
    "ValuesParamsPlatformStructureSVG2",
    "ValuesParamsSuperstructureSleeperWidth",
    "ValuesParamsEarthworkMinStrucHeightUp",
    "ValuesParamsPlatformMedianWidth",
    "ValuesParamsPlatformSVG1",
    "ValuesParamsPlatformLeftover",
    "ValuesParamsSuperstructureBallastWidth",
    "ValuesParamsPlatformPlatformSlope",
    "ValuesParamsSuperstructureRailDensity",
    "ValuesParamsSuperstructureBallastDensity",
    "ValuesParamsSuperstructureLeftRailBallastHeight",
    "ValuesParamsSuperstructureBallastSlope",
    "ValuesParamsSuperstructureSubballastHeight",
    "ValuesParamsOthersDmtBallastComercialPavedRoad",
    "ValuesParamsOthersDmtBallastComercialUnpavedRoad",
    "ValuesParamsOthersDmtBallastLocalGroundRoad",
    "ValuesParamsOthersAquisitionOffset",
    "ValuesParamsEarthworkAvgEarthworkWidth",
    "ValuesParamsOthersAquisitionClearance",
    "ValuesParamsPlatformSVG2",
    "ValuesParamsPlatformSVG2Gutter",
    "ValuesParamsPlatformSVG1Gutter",
    "ValuesParamsPlatformSVG3",
    "ValuesParamsSuperstructureSleeperHeight",
    "ValuesParamsSuperstructureSleeperLength",
    "ValuesParamsSuperstructureSpaceBetweenSleepers",
    "ValuesParamsPlatformSVG4",
    "ValuesParamsEarthworkMaxFill",
    "ValuesParamsStructureMaxBridgeHeight",
    "ValuesParamsPlatformSVG5",
    "ValuesParamsStructureBridgeWidth",
    "ValuesParamsPlatformSVG6",
    "ValuesParamsEarthworkMaxCut",
    "ValuesParamsEarthworkMinStrucHeightDown",
    "ValuesParamsPlatformSVG7",
    "ValuesParamsStructureTunnelWidth",
    "ValuesParamsPlatformSholder",
    "ValuesParamsSuperstructureRevestmentDensity",
    "ValuesParamsPlatformLane",
    "ValuesParamsSuperstructureFirstAsphaltLayerDepth",
    "ValuesParamsSuperstructureSecondAsphaltLayerDepth",
    "ValuesParamsSuperstructureBaseHeight",
    "ValuesParamsSuperstructureSubbaseHeight",
    "ValuesParamsPlatformMedianWidth",
    "divider1",
    "divider2",
    "divider3",
    "divider4",
    "divider5",
    "divider6",
]