<template>
  <div>

    <v-app-bar app clipped-left :color="color" dense dark style="overflow-x: auto;">
      <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
      <v-btn :loading="loading2" text @click="clickCancelExit()">
        <v-icon>mdi-chevron-left</v-icon>{{ selectedLanguage['adjustPrices_component_text3'] }}
      </v-btn>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="capex">Capex</v-tab>
        <v-tab key="expropriation">{{ selectedLanguage['adjustPrices_component_text1'] }}</v-tab>
      </v-tabs>
      <v-btn :loading="loading1" text @click="clickSaveExit()">
        {{ selectedLanguage['adjustPrices_component_text2'] }}<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- <div v-for="(item, idx) in initialList" :key="idx">
      <div :class="idx+' selecao'">
        <v-select label="Select" :items="item" v-model="itemOnList"></v-select>
        <v-btn @click="exitSelecao()">Cancel</v-btn>
        <v-btn @click="saveSelecao(itemOnList)">Save</v-btn>
      </div>
    </div> -->

    <div v-for="(item, idx) in initialList" :key="idx">
        <div :class="idx+' selecao'">
            <!-- <v-select label="Select" :items="item" v-model="itemOnList"></v-select> -->
            <div style="display: flex; flex-direction: column; overflow-y: auto; max-height: 90%; padding: 20px;">
                <div style="display: flex; flex-direction: row; font-weight: bold; font-size: 16px; justify-content: space-between;">
                <p style="max-width: 60%; width: 60%;"> {{ selectedLanguage['Select_capex_dialog_texts_1'] }} </p>
                <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;"> {{ selectedLanguage['Select_capex_dialog_texts_2'] }} </p>
                <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;"> {{ selectedLanguage['Select_capex_dialog_texts_3'] }} </p>
                </div>
            <v-radio-group v-for="(obj, index) in item" :key="index" v-model="item[idenx]">
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <v-radio :label="obj[0]" :value="obj" style="max-width: 60%; width: 60%;"></v-radio>
                <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;">{{ obj[1] }}</p>
                <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;">{{ obj[2] }}</p>
                </div>
            </v-radio-group>
            </div>
            <div style="position: relative; z-index: 999; background-color: #0a7373; display: flex; flex-direction: row; width: 100%; padding: 10px;">
            <v-btn @click="exitSelecao()" style="margin-right: 10px;">{{ selectedLanguage['Select_capex_dialog_texts_4'] }}</v-btn>
            <v-btn @click="saveSelecao(item[idenx])">{{ selectedLanguage['Select_capex_dialog_texts_5'] }}</v-btn>
            </div>
        </div>
    </div>

    <v-card class="elevation-0">
    <v-tabs-items v-model="tab">
    <v-tab-item key="capex">
      <div style="padding: 20px; display: flex; flex-direction: row;" >
        <h4>{{ selectedLanguage['help_text_7'] }} ( {{ currentCalc.currency }} )</h4>
        <v-tooltip max-width="600" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
            <v-icon style="margin-left: 10px;">
              mdi-information-outline
            </v-icon>
            </v-btn>
          </template>
          <span>{{ selectedLanguage['help_text_8'] }}</span>
        </v-tooltip>
      </div>
          <v-card-text>
            <v-data-table :headers="capexHeaders" :items="currentCapex" hide-default-footer disable-pagination item-key="name" dense class="elevation-0">
              <template #item="{ item }">
                <tr v-if="item.enable" :class="capexItemStyle(item)+' trBody'">
                  <td class="codeS">
                    {{item['Code']}}
                  </td>
                  <td style="height: auto;" :id="item['Code']" :class="item['Code']+'value'">
                    {{item['Description']}}
                  </td>
                  <td v-if="item.Unit!=0" :class="item['Code']+'value'">
                    {{item['Unit']}}
                  </td>
                  <td v-else>
                  </td>
                  <td v-if="item.Unit!=0">
                    <v-edit-dialog :return-value.sync="item['Unit Cost']" @save="onSave(item, 'Unit Cost')" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large :class="item['Code']+'value'"> {{item['Unit Cost']}}
                      <template v-slot:input>
                        <v-text-field :rules="[validateInput]" @blur="validateAndFix(item, 'Unit Cost')"
                        v-model.number="item['Unit Cost']" :label="selectedLanguage['adjustPrices_component_text4']" single-line></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td v-else>
                  </td>
                  <td v-if="item.Unit!=0">
                    <v-edit-dialog :return-value.sync="item['Risk']" @save="onSave(item, 'Risk')" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large :class="item['Code']+'value'"> {{item['Risk']}}
                      <template v-slot:input>
                        <v-text-field :rules="[validateInput]" @blur="validateAndFix(item, 'Risk')"
                        v-model.number="item['Risk']" :label="selectedLanguage['adjustPrices_component_text4']" single-line></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td v-else>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>

        <v-tab-item key="expropriation">
          <div style="padding: 20px; display: flex; flex-direction: row;" >
            <h4>{{ selectedLanguage['help_text_9'] }} </h4>
            <v-tooltip max-width="600" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
                <v-icon style="margin-left: 10px;">
                  mdi-information-outline
                </v-icon>
                </v-btn>
              </template>
              <span>{{ selectedLanguage['help_text_10'] }}</span>
            </v-tooltip>
          </div>
          <v-card-text>
            <v-data-table :headers="expropriationHeaders" :items="currentExpropriation" hide-default-footer disable-pagination item-key="Code" dense class="elevation-0">
              <template #item="{ item }">
                <tr :class="expropriationItemStyle(item)+' trBody'">
                  <td class="codeS">
                    {{item['Code']}}
                  </td>
                  <td style="height: auto;">
                    {{item['Description']}}
                  </td>
                  <td>
                    <!-- continuar, precisa achar uma maneira de adicionar o icone junto da troca de valor, e também mudar esse save-text para ser algo q vem do modulo de tradução -->
                    <v-edit-dialog :return-value.sync="item['Cost']" @save="onSave(item, 'Cost')" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Cost']}}
                      <template v-slot:input>
                        <v-text-field :rules="[validateInput]" @blur="validateAndFix(item, 'Cost')"
                        v-model.number="item['Cost']" :label="selectedLanguage['adjustPrices_component_text4']" single-line></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { portuguese } from '../../../languages/pt-br'
  import { english } from '../../../languages/en'

  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';

  export default {
    name: 'AdjustPrices',
    mounted(){
      setTimeout(()=>{
        this.setSelectsOnTable()
        this.setToolTipOnTable()
      },5000)
      this.initializeCurvesComp()

    },
    computed: {
      steps () {
        return this.$store.getters.steps('railway')
      },
      helper(){
        return this.$store.getters.helper
      },
      status () {
        return this.$store.getters.status(this.$route.params.id)
      },
      ...mapGetters(['profile','currentCalc','currentExpropriation','currentCapex','balanceFormatted']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    data() {
      return {
        logoIconUrl: '/img/icons/logo_icon.webp',
        permanent: false,
        drawer: true,
        tab: 'expropriation',
        color: '#0A7373',
        loading1: false,
        loading2: false,
        capexListSelected: undefined,
        capexSelectList: undefined,
        selectToSetCapexList: undefined,
        initialList: this.$store.getters.currentCalc.capex.Variables,
        capexSelect: false,
        idenx: undefined,
        itemOnList: undefined,
        itemOnList1: undefined,
        itemOnList2: undefined,
        itemOnList3: undefined,
        itemOnList4: undefined,
        selectitemBackup: undefined,
        expropriationHeaders: [
            { text: 'Code', value: 'Code', sortable: false},
            { text: 'Description', value: 'Description', sortable: false},
            { text: 'Cost', value: 'Cost', sortable: false},
          ],
          capexHeaders: [
            { text: 'Code', value: 'Code', sortable: false},
            { text: 'Description', value: 'Description', sortable: false},
            { text: 'Unit', value: 'Unit', sortable: false},
            { text: 'Unit Cost', value: 'Unit Cost', sortable: false},
            { text: 'Risk (%)', value: 'Risk', sortable: false},
          ],
      };
    },
    methods: {

      validateInput(value) {
        value = String(value)
        if (value === null || value === undefined || value.trim() === '') {
          return 'Value cannot be empty'
        }
        if (isNaN(value)) {
          return 'Value must be a number'
        }
        if (Number(value) < 0) {
          return 'Value cannot be less than 0'
        }
        return true
      },
      validateAndFix(item, field) {
        item[field] = String(item[field])
        if (item[field] === null || item[field] === undefined || item[field].trim() === '') {
          item[field] = 0
        }
      },
      onSave(item, field) {
        const validationMessage = this.validateInput(item[field])
        if (validationMessage !== true) {
          alert(validationMessage)
          return
        }
        // this.validateAndSave(item)
      },
      // validateAndSave(item) {
      //   console.log('Saving item:', item)
      // },

      initializeCurvesComp(){

        if(this.selectedLanguage['curves_comp_list_arr_of_obj_2'][2]['text'].includes('/m²')){
          // do nothing
        } else {
          this.selectedLanguage['curves_comp_list_arr_of_obj_2'][2]['text'] += ' ('+this.currentCalc.currency+'/m²)'
        }
        this.expropriationHeaders = this.selectedLanguage['curves_comp_list_arr_of_obj_2']

        if(this.selectedLanguage['curves_comp_list_arr_of_obj_3'][3]['text'].includes(')')){
          // do nothing
        } else {
          this.selectedLanguage['curves_comp_list_arr_of_obj_3'][3]['text']
        }

        this.capexHeaders = this.selectedLanguage['curves_comp_list_arr_of_obj_3']

      },

      async setSelected(){
        // this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
        if(!localStorage.getItem("selectedItem")){
          this.selectToSetCapexList = this.selectitemBackup
        } else if(!JSON.parse(localStorage.getItem("selectedItem"))){
          this.selectToSetCapexList = this.selectitemBackup
        } else{
          this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
        }
      },

      async exitSelecao(){
        // this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
        if(!localStorage.getItem("selectedItem")){
          this.selectToSetCapexList = this.selectitemBackup
        } else if(!JSON.parse(localStorage.getItem("selectedItem"))){
          this.selectToSetCapexList = this.selectitemBackup
        } else{
          this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
        }
        document.getElementsByClassName(this.selectToSetCapexList)[0].style.display = 'none'
      },
      async saveSelecao(item){

        var elementSelected = JSON.parse(window.localStorage.getItem('selectedItem'))
        // console.log('Selected Item:', elementSelected)
        var element = document.getElementById(elementSelected)
        // console.log('element is', element, 'is', elementSelected)

        var indexIs = await this.currentCalc.capex.CAPEX.Code.indexOf(this.selectToSetCapexList.toString())

        this.currentCapex[indexIs]['Description'] = item[0]
        document.getElementsByClassName(this.selectToSetCapexList+'value')[0].textContent = item[0]

        this.currentCapex[indexIs]['Unit'] = item[1]
        document.getElementsByClassName(this.selectToSetCapexList+'value')[1].textContent = item[1]

        this.currentCapex[indexIs]['Unit Cost'] = item[2]

        document.getElementsByClassName(this.selectToSetCapexList)[0].style.display = 'none'

        var icon = document.createElement('span')
        icon.className = 'mdi mdi-pencil'

        element.insertBefore(icon, element.firstChild)

      },
      async setSelectsOnTable(){

        var selectToSetCapexList;

        var ListenerKeys = []
        ListenerKeys = Object.keys(this.$store.getters.currentCalc.capex.Variables)

        var self = this;

        for(let i = 0; i < ListenerKeys.length; i++){
          document.getElementById(ListenerKeys[i]).style.cursor = 'pointer'
          document.getElementById(ListenerKeys[i]).addEventListener('click', function(e) {
            selectToSetCapexList = e.target.id
            window.localStorage.setItem('selectedItem', ('"'+selectToSetCapexList+'"'))
            this.selectitemBackup = ('"'+selectToSetCapexList+'"')
            self.setSelected()
            document.getElementsByClassName(e.target.id)[0].style.display = 'block'
          });

          var element = document.getElementById(ListenerKeys[i])

          var icon = document.createElement('span')
          icon.className = 'mdi mdi-pencil'

          element.insertBefore(icon, element.firstChild)
        }

      },

      async setToolTipOnTable() {
            var ListenerKeys = [];
            ListenerKeys = Object.keys(this.$store.getters.currentCalc.capex.Details);

            for (let i = 0; i < ListenerKeys.length; i++) {
                let element = document.getElementById(ListenerKeys[i])

                let ObjtooltipText = this.$store.getters.currentCalc.capex.Details[ListenerKeys[i]]
                var ArrtooltipText = '';
                for(let j = 0; j < ObjtooltipText.length ; j++){
                    ArrtooltipText += this.$store.getters.currentCalc.capex.Details[ListenerKeys[i]][j]+'<br>'
                }

                // Substitui os espaços por quebras de linha (se necessário)
                // var newtxt = ArrtooltipText.replaceAll('|', '<br>')

                let tooltipIcon = document.createElement('i')
                tooltipIcon.className = "mdi mdi-information"
                tooltipIcon.style.cursor = "pointer"
                tooltipIcon.style.marginLeft = "10px"
                tooltipIcon.style.fontSize = "20px"

                element.appendChild(tooltipIcon)

                // Usa Tippy.js para criar um tooltip personalizado
                tippy(tooltipIcon, {
                    content: ArrtooltipText,
                    allowHTML: true
                })
            }
        },

      redirectFunc(){
        if(process.env.VUE_APP_VAR_SELECT== 'prd'){
          this.$router.push({ name: 'billingMercadoPago' })
        } else {
          this.$router.push({ name: 'billing' })
        }
      },
      async goStep(step){
        await this.changeStatus({calcId: this.$route.params.id, payload: {'status': 'Init','step_name': this.steps[step-1], 'step': step, 'notification': this.status.notification}})
      },
      ...mapActions(['updateRasterBudget','updateCapex','changeStatus']),
      save() {},
      cancel() {},
      open() {},
      close() {},
      expropriationItemStyle(item) {
        if(item.Cost!=undefined){ return 'capexLastLevel'}
        if(item.Level==0){ return 'capexZeroLevel'}
        if(item.Level==1){ return 'capexFirstLevel'}
        else{ return 'capexLastLevel'}
      },
      capexItemStyle(item) {
        if(item.Unit!=0){ return 'capexLastLevel'}
        if(item.Level==0){ return 'capexZeroLevel'}
        if(item.Level==1){ return 'capexFirstLevel'}
        if(item.Level==2){ return 'capexSecondLevel'}
        if(item.Level==3){ return 'capexThirdLevel'}
        return ''
      },
      async clickSaveExit() {
        this.loading1 = true
        await (
          this.updateRasterBudget(),
          this.updateCapex()
        )
        await this.goStep(3)
        this.loading1 = false
      },
      async clickCancelExit() {
        this.loading2 = true
        await this.goStep(1)
        this.loading2 = false
      }
    },
  }
</script>

<style scoped>

.selecao{
    display: none;
    position: fixed;
    z-index: 9999;
    background-color: white;
    /* padding: 30px; */
    /* border-radius: 10%; */
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 70vw;
    height: 50vh;
    /* overflow-y: auto; */
    -webkit-box-shadow: 10px 10px 87px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 87px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 87px 0px rgba(0,0,0,0.75);
  }

  .capexLastLevel {
    font-style: italic
  }
  .capexZeroLevel {
    background-color: #0A7373;
    font-weight: bold
  }
  .capexFirstLevel {
    background-color: #338D8C;
    font-weight: bold
  }
  .capexSecondLevel {
    background-color: #36bebc;
    font-weight: bold
  }
  .capexThirdLevel {
    background-color: #41e2df;
    font-weight: bold
  }

  @media only screen and (max-width: 576px) {
		.codeS {
			display: none;
		}
    .trBody{
      display: flex;
      justify-content: space-between;
    }
	}
</style>