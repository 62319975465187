<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row class="disableOnUpdate">
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformSVG4Title">
        <strong> {{ selectedLanguage['platformBridge_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <br>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 462.23 262.8" id="ValuesParamsPlatformSVG4">
      <title>{{ selectedLanguage['platformBridge_component_text2'] }}</title>
      <g id="Terreno">
        <path
          class="cls-1"
          d="M5.19,7.09c23,7.59,47.18,19.68,68.62,30.85,40.74,21.23,83,39.35,125.23,57.45,10.12,4.34,20.3,8.7,31,11.11,11.52,2.58,23.41,2.87,35.21,3.14l40.18.94c16.78.39,34.18.67,49.59-6,16.14-7,28.18-20.73,39.66-34l38.12-44.25c4.09-4.75,8.31-9.61,13.83-12.6s13-10.11,18.23-6.6c0,0-.19,126.23,0,125.2L7,132.74,5.19,7.09"
          transform="translate(-5.19 -6.14)"
        />
        <path
          class="cls-1"
          d="M7.44,143.29c23,7.59,47.18,19.68,68.61,30.85,40.74,21.24,83,39.36,125.23,57.46,10.12,4.33,20.3,8.69,31,11.1,11.52,2.59,23.41,2.87,35.21,3.15l40.18.93c16.78.39,34.18.67,49.59-6,16.14-7,28.19-20.73,39.66-34Q416,184.62,435.08,162.5c4.09-4.76,8.31-9.62,13.83-12.61s13-10.1,18.24-6.6c0,0-.2,126.24,0,125.21l-457.88.44L7.44,143.29"
          transform="translate(-5.19 -6.14)"
        />
      </g>
      <g id="Água">
        <path
          class="cls-2"
          d="M380.61,86.43H178.54c2.33.38,5.24,3.08,7.4,4.22,2.71,1.43,5.49,2.72,8.28,4l4.85,2.26a186.64,186.64,0,0,0,17.38,7.37c16,5.53,32.24,4.92,48.88,5.27,16.2.33,32.38,3.78,48.51,2.16,5.92-.6,11.77-1.88,17.72-2,4.37-.09,8.78.44,13.09-.32,5.45-1,10.31-3.92,15-7C366.84,97.71,373.81,91.79,380.61,86.43Z"
          transform="translate(-5.19 -6.14)"
        />
      </g>
      <g id="Ponte">
        <polyline class="cls-3" points="71.46 2.12 414.49 2.12 414.49 2.58" />
        <polyline
          class="cls-3"
          points="73.7 138.33 416.74 138.33 416.74 138.78"
        />
      </g>
      <g id="Aterro">
        <path
          class="cls-4"
          d="M6.65,7.34H76.23L99,32.55c11.32,12.52,22.75,25.14,36.46,35-13.1-.29-23.72-9.32-35.15-15.74-13.7-7.7-29-12.24-42.83-19.64-3.13-1.67-5.84-4.34-9.28-5.24C26.2,21.11,4.66,9.69,6.65,7.34Z"
          transform="translate(-5.19 -6.14)"
        />
        <path
          class="cls-4"
          d="M437.56,7.55h-12c-1.45,0-3.26-.33-4.51.32s-2.62,3-3.59,4.06c-.73.82-1.45,1.64-2.17,2.47-1.64,1.88-3.24,3.8-4.84,5.71l-1.79,2.13c-2,2.37-4.51,4.47-5,7.47-.53,3.39.06,7.34.12,10.77.06,3.59.13,7.17.18,10.75,0,1.45,1,8.77.08,9.64,9.49-8.68,17-19.79,25.5-29.49a156.49,156.49,0,0,1,13.48-14.13,73,73,0,0,1,7.59-6c1.62-1.1,4.78-2.23,6-3.67"
          transform="translate(-5.19 -6.14)"
        />
        <path
          class="cls-4"
          d="M8.18,143.54H78.47l22.8,25.21c11.31,12.52,22.75,25.14,36.46,35-13.11-.29-23.73-9.33-35.16-15.75-13.7-7.69-29-12.24-42.83-19.64-3.13-1.67-5.84-4.33-9.28-5.24C28.44,157.31,6.19,145.89,8.18,143.54Z"
          transform="translate(-5.19 -6.14)"
        />
        <path
          class="cls-4"
          d="M439.8,143.76h-12c-1.45,0-3.26-.33-4.51.32s-2.62,3-3.59,4l-2.17,2.48c-1.64,1.88-3.24,3.79-4.84,5.7l-1.79,2.13c-2,2.38-4.51,4.47-5,7.47-.52,3.39.07,7.34.12,10.78s.13,7.16.18,10.75c0,1.44,1,8.76.08,9.63,9.49-8.68,17.05-19.78,25.5-29.48a156.66,156.66,0,0,1,13.49-14.14,73.15,73.15,0,0,1,7.58-6c1.62-1.1,5.82-2.71,7-4.15"
          transform="translate(-5.19 -6.14)"
        />
      </g>
      <g id="Contenção">
        <line class="cls-5" x1="397.61" y1="22.05" x2="397.61" y2="56.3" />
        <line class="cls-5" x1="399.85" y1="158.26" x2="399.85" y2="192.5" />
      </g>
      <g id="Grade">
        <line class="cls-6" x1="2.24" y1="137.16" x2="462.23" y2="137.16" />
        <line class="cls-6" y1="0.96" x2="459.99" y2="0.96" />
      </g>
      <g id="Medidas">
        <text class="cls-7" transform="translate(420.08 17.38)">{{currentCalc.values_params.earthwork.min_struc_height_up}} m</text>
        <text class="cls-7" transform="translate(40.81 18.61)">{{currentCalc.values_params.earthwork.min_struc_height_up}} m</text>
        <text class="cls-7" transform="translate(422.32 153.58)">{{currentCalc.values_params.earthwork.min_struc_height_up}} m</text>
        <text class="cls-7" transform="translate(43.05 154.82)">{{currentCalc.values_params.earthwork.min_struc_height_up}} m</text>
        <text class="cls-8" transform="translate(282.75 190.9)">&gt;{{ currentCalc.values_params.earthwork.max_fill }} m</text>
        <line class="cls-9" x1="71.46" y1="34.18" x2="71.46" y2="2.91" />
        <polygon
          class="cls-10"
          points="72.31 33.18 71.46 34.03 70.61 33.18 70.61 33.9 71.46 34.75 72.31 33.9 72.31 33.18"
        />
        <polygon
          class="cls-10"
          points="72.31 3.9 71.46 3.05 70.61 3.9 70.61 3.18 71.46 2.33 72.31 3.18 72.31 3.9"
        />
        <line class="cls-9" x1="416.77" y1="33.66" x2="416.77" y2="2.39" />
        <polygon
          class="cls-10"
          points="417.62 32.67 416.77 33.52 415.93 32.67 415.93 33.39 416.77 34.24 417.62 33.39 417.62 32.67"
        />
        <polygon
          class="cls-10"
          points="417.62 3.39 416.77 2.54 415.93 3.39 415.93 2.67 416.77 1.82 417.62 2.67 417.62 3.39"
        />
        <line class="cls-9" x1="73.11" y1="170.24" x2="73.11" y2="138.97" />
        <polygon
          class="cls-10"
          points="73.96 169.25 73.11 170.1 72.27 169.25 72.27 169.97 73.11 170.81 73.96 169.97 73.96 169.25"
        />
        <polygon
          class="cls-10"
          points="73.96 139.97 73.11 139.12 72.27 139.97 72.27 139.25 73.11 138.4 73.96 139.25 73.96 139.97"
        />
        <line class="cls-9" x1="419.08" y1="170.22" x2="419.08" y2="138.94" />
        <polygon
          class="cls-10"
          points="419.93 169.22 419.08 170.07 418.23 169.22 418.23 169.94 419.08 170.79 419.93 169.94 419.93 169.22"
        />
        <polygon
          class="cls-10"
          points="419.93 139.94 419.08 139.09 418.23 139.94 418.23 139.22 419.08 138.37 419.93 139.22 419.93 139.94"
        />
        <rect class="cls-10" x="68.23" y="35.43" width="6.69" height="0.46" />
        <rect class="cls-10" x="68.23" y="1.43" width="6.69" height="0.46" />
        <rect class="cls-10" x="70.23" y="137.43" width="6.69" height="0.46" />
        <rect class="cls-10" x="70.23" y="171.43" width="6.69" height="0.46" />
        <rect class="cls-10" x="416.23" y="137.43" width="6.69" height="0.46" />
        <rect class="cls-10" x="416.23" y="171.43" width="6.69" height="0.46" />
        <rect class="cls-10" x="414.23" y="1.43" width="6.69" height="0.46" />
        <line class="cls-11" x1="279.16" y1="238.44" x2="279.16" y2="140.69" />
        <polygon
          points="280.01 237.45 279.16 238.29 278.31 237.45 278.31 238.17 279.16 239.01 280.01 238.17 280.01 237.45"
        />
        <polygon
          points="280.01 141.69 279.16 140.84 278.31 141.69 278.31 140.97 279.16 140.12 280.01 140.97 280.01 141.69"
        />
        <rect class="cls-10" x="413.23" y="34.43" width="6.69" height="0.46" />
        <rect x="276.23" y="139.43" width="6.69" height="0.46" />
        <rect x="276.23" y="239.43" width="6.69" height="0.46" />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkMaxFill">
        <v-col style="align-self: center;"> {{ selectedLanguage['platformBridge_component_text3'] }} </v-col>
        <v-col style="align-self: center;">
          <v-text-field dense hide-details class="mb-2" clearable outlined v-model.number="currentCalc.values_params.earthwork.max_fill" style="border-radius: 10px !important;"/>
        </v-col>
        <v-col style="align-self: center;"> m </v-col>
      </v-row>
      <v-row id="ValuesParamsEarthworkMinStrucHeightUp">
        <v-col style="align-self: center;"> {{ selectedLanguage['platformBridge_component_text4'] }} </v-col>
        <v-col style="align-self: center;">
          <v-text-field dense hide-details class="mb-2" clearable outlined v-model.number="currentCalc.values_params.earthwork.min_struc_height_up" style="border-radius: 10px !important;"/>
        </v-col>
        <v-col style="align-self: center;"> m </v-col>
      </v-row>
      <v-row id="ValuesParamsStructureMaxBridgeHeight">
        <v-col style="align-self: center;"> {{ selectedLanguage['platformBridge_component_text5'] }} </v-col>
        <v-col style="align-self: center;">
          <v-text-field dense hide-details class="mb-2" clearable outlined v-model.number="currentCalc.values_params.structure.max_bridge_height" style="border-radius: 10px !important;"/>
        </v-col>
        <v-col style="align-self: center;"> m </v-col>
      </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-2 {
  fill: #8a9bff;
}
.cls-11,
.cls-3,
.cls-5,
.cls-6,
.cls-9 {
  fill: none;
  stroke-miterlimit: 10;
}
.cls-3,
.cls-5 {
  stroke: #666;
}
.cls-3 {
  stroke-width: 3px;
}
.cls-4 {
  fill: #875116;
}
.cls-5 {
  stroke-width: 4px;
}
.cls-6 {
  stroke: #3d3d3d;
}
.cls-7,
.cls-8 {
  font-size: 14px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
.cls-10,
.cls-7 {
  fill: #fff;
}
.cls-9 {
  stroke: #fff;
}
.cls-11,
.cls-9 {
  stroke-width: 0.5px;
}
.cls-11 {
  stroke: #000;
}
</style>