<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformSVG1Title">
        <strong> {{ selectedLanguage['platform1Lane_railway_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <br />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.37 104.73" id="ValuesParamsPlatformSVG1">
      <title>{{ selectedLanguage['platform1Lane_railway_component_text2'] }}</title>
      <g id="Sublastro">
        <polyline
          class="cls-1"
          points="1 61.85 9.77 51.12 9.97 50.94 81.2 50.79 141.41 50.6 150.77 61.29"
        />
      </g>
      <g id="Lastro">
        <polygon
          class="cls-2"
          points="122.72 51.19 106.13 51.16 49.41 51.16 32.95 51.19 41.74 40.49 41.94 40.3 113.17 40.3 113.38 40.49 122.72 51.19"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-3" x="57.1" y="40.3" width="42.06" height="3.93" />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-4"
          points="95.63 38.93 95.63 40.3 91.1 40.3 91.1 38.93 92.76 38.93 92.76 35.04 92.15 35.04 92.15 32.61 94.58 32.61 94.58 35.04 93.98 35.04 93.98 38.93 95.63 38.93"
        />
        <polygon
          class="cls-4"
          points="65.33 38.93 65.33 40.3 60.8 40.3 60.8 38.93 62.46 38.93 62.46 35.04 61.85 35.04 61.85 32.61 64.28 32.61 64.28 35.04 63.67 35.04 63.67 38.93 65.33 38.93"
        />
      </g>
      <g id="Medidas">
        <line class="cls-5" x1="54.71" y1="32.22" x2="59.23" y2="34.83" />
        <path
          d="M94.94,38.47a3.65,3.65,0,0,1,.45-2.37,6.8,6.8,0,0,0,1.26,3.36,7.22,7.22,0,0,0-3.54.58A3.83,3.83,0,0,1,94.94,38.47Z"
          transform="translate(-35.88 -3.74)"
        />
        <text class="cls-6" transform="translate(20.52 28.65)">{{ currentCalc.values_params.superstructure.rail_density }} kg/m</text>
        <line class="cls-5" x1="82.74" y1="75.47" x2="70.31" y2="75.47" />
        <polygon
          points="71.31 74.63 70.46 75.47 71.31 76.32 70.59 76.32 69.74 75.47 70.59 74.63 71.31 74.63"
        />
        <text class="cls-6" transform="translate(69.35 71.46)">{{ currentCalc.values_params.platform.platform_slope }} %</text>
        <line class="cls-5" x1="1.74" y1="64.15" x2="1.76" y2="73.36" />
        <line class="cls-5" x1="32.03" y1="64.87" x2="32.03" y2="73.64" />
        <text class="cls-6" transform="translate(6.85 81.34)">{{ currentCalc.values_params.platform.leftover }} m</text>
        <line class="cls-5" x1="3.31" y1="69.08" x2="30.46" y2="69.44" />
        <polygon
          points="4.29 69.94 3.46 69.08 4.32 68.25 3.6 68.24 2.74 69.07 3.58 69.93 4.29 69.94"
        />
        <polygon
          points="29.45 70.28 30.31 69.44 29.48 68.58 30.2 68.59 31.03 69.45 30.17 70.29 29.45 70.28"
        />
        <line class="cls-5" x1="119.74" y1="64.87" x2="119.74" y2="74.09" />
        <line class="cls-5" x1="150.03" y1="65.87" x2="150.03" y2="74.64" />
        <text class="cls-6" transform="translate(124.85 82.34)">{{ currentCalc.values_params.platform.leftover }} m</text>
        <line class="cls-5" x1="121.31" y1="70.08" x2="148.46" y2="70.44" />
        <polygon
          points="122.3 70.94 121.46 70.08 122.32 69.25 121.6 69.24 120.74 70.07 121.58 70.93 122.3 70.94"
        />
        <polygon
          points="147.45 71.28 148.31 70.44 147.48 69.58 148.19 69.59 149.03 70.45 148.17 71.29 147.45 71.28"
        />
        <line class="cls-5" x1="42.57" y1="10.68" x2="42.57" y2="19.9" />
        <line class="cls-5" x1="112.87" y1="11.68" x2="112.87" y2="20.45" />
        <text class="cls-6" transform="matrix(1, 0.01, -0.01, 1, 62.72, 10.04)">
            {{ currentCalc.values_params.superstructure.ballast_width }} m
        </text>
        <line class="cls-5" x1="44.15" y1="15.89" x2="111.3" y2="16.26" />
        <polygon
          points="45.14 16.74 44.29 15.89 45.15 15.04 44.43 15.04 43.57 15.88 44.42 16.74 45.14 16.74"
        />
        <polygon
          points="110.3 17.1 111.15 16.26 110.3 15.4 111.02 15.41 111.87 16.26 111.02 17.11 110.3 17.1"
        />
        <rect class="cls-7" x="152.54" y="50.94" width="6.69" height="0.46" />
        <rect class="cls-7" x="152.54" y="60.94" width="6.69" height="0.46" />
        <line class="cls-5" x1="155.89" y1="60.04" x2="155.89" y2="52.29" />
        <polygon
          points="156.73 59.05 155.88 59.89 155.04 59.05 155.04 59.77 155.88 60.61 156.73 59.77 156.73 59.05"
        />
        <polygon
          points="156.73 53.29 155.88 52.44 155.04 53.29 155.04 52.57 155.88 51.72 156.73 52.57 156.73 53.29"
        />
        <text class="cls-6" transform="translate(165.02 60.28)">{{ currentCalc.values_params.superstructure.subballast_height }} m</text>
        <rect class="cls-7" x="123.37" y="39.94" width="6.69" height="0.46" />
        <rect class="cls-7" x="123.37" y="49.94" width="6.69" height="0.46" />
        <line class="cls-5" x1="126.71" y1="49.04" x2="126.71" y2="41.29" />
        <polygon
          points="127.56 48.05 126.71 48.89 125.86 48.05 125.86 48.77 126.71 49.61 127.56 48.77 127.56 48.05"
        />
        <polygon
          points="127.56 42.29 126.71 41.44 125.86 42.29 125.86 41.57 126.71 40.72 127.56 41.57 127.56 42.29"
        />
        <text class="cls-6" transform="translate(132.84 49.28)">{{
            currentCalc.values_params.superstructure.left_rail_ballast_height
          }}
          m</text>
        <line class="cls-5" x1="107.43" y1="31.81" x2="108.78" y2="36.85" />
        <path
          d="M144.61,40.39a3.65,3.65,0,0,1,2-1.35,6.71,6.71,0,0,0-1.48,3.26,7.25,7.25,0,0,0-2.91-2.09A3.79,3.79,0,0,1,144.61,40.39Z"
          transform="translate(-35.88 -3.74)"
        />
        <text class="cls-6" transform="translate(100.11 28.86)">{{ currentCalc.values_params.superstructure.ballast_density }} t/m³</text>
        <polygon
          class="cls-5"
          points="30.24 48.97 30.24 40.97 30.74 40.97 36.74 40.97 37.24 40.97 30.24 48.97 30.24 48.97"
        />
        <text class="cls-6" transform="translate(0 48.5)">1:{{
            Math.round(
              (1 / currentCalc.values_params.superstructure.ballast_slope) * 10
            ) / 10
          }}</text>
        <line class="cls-5" x1="1.15" y1="84.53" x2="1.17" y2="93.75" />
        <line class="cls-5" x1="150.44" y1="85.26" x2="150.44" y2="94.02" />
        <text class="cls-6" transform="translate(64.26 101.73)">{{ platformWidth }} m</text>
        <line class="cls-5" x1="2.72" y1="89.46" x2="148.87" y2="89.84" />
        <polygon
          points="3.71 90.31 2.87 89.46 3.72 88.62 3 88.62 2.15 89.46 2.99 90.31 3.71 90.31"
        />
        <polygon
          points="147.87 90.68 148.72 89.84 147.88 88.99 148.6 88.99 149.44 89.84 148.59 90.68 147.87 90.68"
        />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsPlatformLeftover">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text3'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.platform.leftover"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureBallastWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text4'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.superstructure.ballast_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsPlatformPlatformSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.platform.platform_slope"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureRailDensity">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                currentCalc.values_params.superstructure.rail_density
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> kg/m </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureBallastDensity">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text7'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            currentCalc.values_params.superstructure.ballast_density
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> t/m³ </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureLeftRailBallastHeight">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text8'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            currentCalc.values_params.superstructure.left_rail_ballast_height
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureBallastSlope">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text9'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            currentCalc.values_params.superstructure.ballast_slope
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text10'] }} </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureSubballastHeight">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text11'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            currentCalc.values_params.superstructure.subballast_height
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsOthersDmtBallastComercialPavedRoad">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text12'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            currentCalc.values_params.others.dmt_ballast_comercial_paved_road
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> Km </v-col>
    </v-row>
    <v-row id="ValuesParamsOthersDmtBallastComercialUnpavedRoad">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text13'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            currentCalc.values_params.others.dmt_ballast_comercial_unpaved_road
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> Km </v-col>
    </v-row>
    <v-row id="ValuesParamsOthersDmtBallastLocalGroundRoad">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_railway_component_text14'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            currentCalc.values_params.others.dmt_ballast_local_ground_road
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> Km </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
    platformWidth: function () {
      let slope_width =
        this.currentCalc.values_params.superstructure.left_rail_ballast_height *
        this.currentCalc.values_params.superstructure.ballast_slope;
      return (
        slope_width*2 +
        this.currentCalc.values_params.superstructure.ballast_width +
        this.currentCalc.values_params.platform.leftover * 2
      ).toFixed(2);
    },
  },
  data() {
    return {
      side: ["Both", "Right", "Left"],
      side_selected: "Both",
    };
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #c69c6d;
}
.cls-2 {
  fill: #ccc;
}
.cls-3 {
  fill: #666;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  fill: none;
}
.cls-5,
.cls-7 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 12px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>