export var listValuesParams =
    [
    "ValuesParamsAxisGeometryMaxGrade",
    "ValuesParamsAxisGeometryGradeTitle",
    "ValuesParamsAxisGeometryDivider",
    "ValuesParamsAxisGeometryTitle",
    "ValuesParamsAxisGeometryMinGrade",
    "ValuesParamsSuperelevationSpeedCoeficientTitle",
    "ValuesParamsAxisGeometryVerticalCurveLength",
    "ValuesParamsAxisGeometry",
    "ValuesParamsSuperelevationGauge",
    "ValuesParamsSuperelevationBoletoIndex",
    "ValuesParamsSuperelevationOperationalSpeed",
    "ValuesParamsSuperelevationSecurityCoeficient",
    "ValuesParamsSuperelevationCenterGravityHeight",
    "ValuesParamsSuperelevationCenterGravityOffset",
    "ValuesParamsSuperelevationSpeedCoeficient",
    "ValuesParamsAxisGeometryMinCircularCurveRadius",
    "ValuesParamsCurveChart",
    "divider1",
    "divider2",
    "divider3",
    "divider4",
    "divider5",
    "divider6",
]