<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <svg
      id="ValuesParamsPlatformSVG7"
      data-name="Seção 1 Pista"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 177.69 152.78"
    >
      <title>tunnel_section_1lane</title>
      <g id="Lastro">
        <polyline
          class="cls-1"
          points="174.69 131.36 48.22 131.01 48.22 116.56 48.22 116.56 59.05 116.56 59.16 116.56 174.69 116.56"
        />
      </g>
      <g id="Estrutura">
        <polyline
          class="cls-2"
          points="47.74 65.26 47.74 131.36 174.69 131.36 174.69 63.2"
        />
        <path
          class="cls-2"
          d="M50.84,71.84c0-34.63,28.42-62.7,63.47-62.7s63.48,28.07,63.48,62.7"
          transform="translate(-3.1 -6.14)"
        />
      </g>
      <g id="Dormente">
        <rect class="cls-3" x="75.51" y="115.74" width="71.4" height="6.88" />
      </g>
      <g id="Trilho">
        <polygon
          class="cls-4"
          points="93.49 114.26 93.49 115.74 88.59 115.74 88.59 114.26 90.39 114.26 90.39 110.06 89.73 110.06 89.73 107.42 92.36 107.42 92.36 110.06 91.7 110.06 91.7 114.26 93.49 114.26"
        />
        <polygon
          class="cls-4"
          points="135.49 114.26 135.49 115.74 130.59 115.74 130.59 114.26 132.39 114.26 132.39 110.06 131.73 110.06 131.73 107.42 134.36 107.42 134.36 110.06 133.7 110.06 133.7 114.26 135.49 114.26"
        />
      </g>
      <g id="Medidas">
        <path
          class="cls-5"
          d="M180.51,154.29"
          transform="translate(-3.1 -6.14)"
        />
        <path
          class="cls-5"
          d="M48.46,154.29"
          transform="translate(-3.1 -6.14)"
        />
        <line class="cls-5" x1="45.57" y1="143.56" x2="45.57" y2="152.78" />
        <line class="cls-5" x1="177.2" y1="143.56" x2="177.2" y2="152.32" />
        <path
          class="cls-5"
          d="M37.18,140.4"
          transform="translate(-3.1 -6.14)"
        />
        <path class="cls-5" d="M37.18,68" transform="translate(-3.1 -6.14)" />
        <path class="cls-5" d="M37.18,10" transform="translate(-3.1 -6.14)" />
        <line class="cls-5" x1="31.05" y1="61.17" x2="37.39" y2="61.17" />
        <line class="cls-5" x1="30.84" y1="2.83" x2="37.17" y2="2.83" />
        <line class="cls-5" x1="31.19" y1="134.26" x2="37.52" y2="134.26" />
        <text class="cls-6" transform="translate(0 34.68) scale(0.9 1)">
          3.25m
        </text>
        <text class="cls-6" transform="translate(3.58 97.66) scale(0.9 1)">
          5.1m
        </text>
        <text class="cls-6" transform="translate(103.99 145.88) scale(0.9 1)">
          {{currentCalc.values_params.structure.tunnel_width}} m
        </text>
        <line class="cls-5" x1="34.08" y1="60.19" x2="34.08" y2="3.57" />
        <polygon
          points="34.93 59.19 34.08 60.04 33.23 59.19 33.23 59.91 34.08 60.76 34.93 59.91 34.93 59.19"
        />
        <polygon
          points="34.93 4.57 34.08 3.72 33.23 4.57 33.23 3.85 34.08 3 34.93 3.85 34.93 4.57"
        />
        <line class="cls-5" x1="34.41" y1="133.62" x2="34.14" y2="62.02" />
        <polygon
          points="35.26 132.62 34.41 133.47 33.56 132.63 33.56 133.35 34.41 134.19 35.26 133.34 35.26 132.62"
        />
        <polygon
          points="34.99 63.02 34.14 62.17 33.3 63.02 33.29 62.3 34.14 61.45 34.99 62.3 34.99 63.02"
        />
        <line class="cls-5" x1="46.46" y1="148.37" x2="176.44" y2="148.09" />
        <polygon
          points="47.46 149.21 46.61 148.37 47.46 147.52 46.74 147.52 45.89 148.37 46.74 149.22 47.46 149.21"
        />
        <polygon
          points="175.45 148.94 176.3 148.09 175.45 147.24 176.17 147.24 177.02 148.09 176.17 148.94 175.45 148.94"
        />
      </g>
    </svg>
    <v-row>
      <v-col>
        <v-row id="ValuesParamsStructureTunnelWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['platformTunnel1Lane_component_text4'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="currentCalc.values_params.structure.tunnel_width"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["currentCalc","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  created: async function () {},
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #ccc;
}
.cls-2,
.cls-5 {
  fill: none;
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke: #666;
  stroke-width: 6px;
}
.cls-3 {
  fill: #666;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  stroke: #000;
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 12px;
  font-family: MyriadPro-Regular, Myriad Pro;
}
</style>