<template>
  <div>
    <v-app-bar app clipped-left :color="color" dense dark>
      <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
      <v-tabs v-model="tab" >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-spacer/>
        <v-tab key="restrictions" @click="setRestrictedArea()" style="color: white !important;"><v-icon style="color: white !important;">mdi-map</v-icon>{{ selectedLanguage['costMapUpdate_component_text1'] }}</v-tab>
        <v-tab v-if="drawType!=undefined" @click="clearDrawtype()" style="color: white !important;">{{ selectedLanguage['costMapUpdate_component_text2'] }}</v-tab>
        <v-tab disabled v-if="drawType!=undefined && this.itHasFeatureLength == 0" @click="callUpdateLimit()" style="color: white !important;">{{ selectedLanguage['costMapUpdate_component_text3'] }}</v-tab>
        <v-tab v-else-if="drawType!=undefined && this.itHasFeatureLength > 0" @click="callUpdateLimit()" style="color: white !important;">{{ selectedLanguage['costMapUpdate_component_text3'] }}</v-tab>
        <v-spacer/>
        <!-- LAYER MENU -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="align-self: center;" text dark v-bind="attrs" v-on="on"><v-icon>mdi-layers-triple-outline</v-icon>{{ selectedLanguage['costMapUpdate_component_text4'] }}</v-btn>
          </template>
          <v-list dense>
            <v-subheader>{{ selectedLanguage['costMapUpdate_component_text5'] }}</v-subheader>
              <v-list-item-group v-model="baseLayerIdx" color="primary">
                <v-list-item v-for="(item, i) in baseLayers" :key="i">
                  <v-list-item-icon>
                    <v-icon>mdi-checkerboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"/>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
          </v-list>
        </v-menu>
        <v-spacer/>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="align-self: center;" text dark v-bind="attrs" v-on="on"><v-icon>mdi-layers-triple-outline</v-icon>{{ selectedLanguage['costMapUpdate_component_text6'] }}</v-btn>
          </template>
          <v-list dense style="max-height: 50vh;">
            <v-subheader>{{ selectedLanguage['costMapUpdate_component_text7'] }}</v-subheader>
              <v-list-item-group v-model="vectorsTypeSelected" color="primary" style="background-color: white;">
                <v-list-item v-for="(item, i) in vectorsType" :key="i" style="min-height: 0 !important;">
                  <div v-if="item === 'protect_class'">
                    <v-checkbox v-model="toggleList[i]" :label="item"></v-checkbox>
                  </div>
                  <div v-else style="max-height: 0 !important;"></div>
                </v-list-item>
                <v-list-item>
                  <v-checkbox checked v-model="toggle" :label="selectedLanguage['costMapUpdate_component_text8']"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox checked v-model="toggleLimitArea" :label="selectedLanguage['costMapUpdate_component_text9']"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox checked v-model="togglePoint" :label="selectedLanguage['costMapUpdate_component_point']"></v-checkbox>
                </v-list-item>
              </v-list-item-group>
          </v-list>
        </v-menu>
        <v-spacer/>
        <div style="align-self: center;" v-if="btnNext === false">
          <v-btn disabled :loading="loading1" text @click="callChargeCredits()">
            {{ selectedLanguage['costMapUpdate_component_text10'] }}
          </v-btn>
          <v-btn style="align-self: center;" disabled>
            {{ selectedLanguage['costMapUpdate_component_text11'] }}<v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div style="align-self: center;" v-else-if="status.status=='HasChanges' && btnNext === true">
          <v-btn :loading="loading1" text @click="callChargeCredits()">
            {{ selectedLanguage['costMapUpdate_component_text10'] }}
          </v-btn>
          <v-btn style="align-self: center;" disabled>
            {{ selectedLanguage['costMapUpdate_component_text11'] }}<v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div style="align-self: center;" v-else-if="this.itHasFeatureLength > 0">
          <v-btn :loading="loading1" text disabled>
            {{ selectedLanguage['costMapUpdate_component_text10'] }}
          </v-btn>
          <v-btn style="align-self: center;" disabled>
            {{ selectedLanguage['costMapUpdate_component_text11'] }}<v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div style="align-self: center;" v-else-if="status.status=='Init'">
          <v-btn style="align-self: center;" :loading="loading1" text @click="clickSaveExit()">
            {{ selectedLanguage['costMapUpdate_component_text11'] }}<v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-btn style="align-self: center;" text @click="redirectFunc()">₡ {{this.balanceFormatted}}</v-btn>
        <NotificationMenu/>
      </v-tabs>
    </v-app-bar>
    <v-card class="elevation-0">
      <v-tabs-items v-model="tab">
        <v-tab-item key="map">
          <v-alert v-if="downloadingLayers" border="top" dense color="info" dark style="position: fixed; z-index: 9; width: 100%;">
            <v-progress-circular indeterminate :color="color"></v-progress-circular>
            {{ selectedLanguage['costMapUpdate_component_text12'] }}
          </v-alert>
          <v-alert v-if="errorMessageMap" dense border="top" color="error" dark>
            {{ errorMessageMap }}
          </v-alert>
        </v-tab-item>
        <v-tab-item key="restrictions"></v-tab-item>
      </v-tabs-items>
      <Map ref="mapComparative" v-if="mapToggle === true" :srid="srid" @itHasFeature="checkFeatures" :toggleLimitArea="toggleLimitArea" :togglePoint="togglePoint" :toggle="toggle" :layersGeometry="vectors" :layerLimit="[alternativePolygon]" :layerPoint="[track]" :drawType="drawType" :isGeometry="true" :layers="[buffer]" :initZoom="true" :layersIndex="vectorsType" :baseLayerIdx="baseLayerIdx"></Map>
    </v-card>
    <v-dialog v-if="currentItem" v-model="confirmDetailDialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedLanguage['costMapUpdate_component_text13'] }}
        </v-card-title>
        <v-card-text>{{ selectedLanguage['costMapUpdate_component_text14'] }} ₡{{ (-currentItem.calc_price).toLocaleString() }}</v-card-text>
        <v-alert v-if="errorMessage" dense border="top" color="error" dark>
          {{ errorMessage }}
        </v-alert>
        <v-alert v-if="cloudPasswordInput" dense border="top" color="warning" dark>
          {{ selectedLanguage['costMapUpdate_component_text15'] }}<br>
          <v-text-field v-model="cloudPassword" :rules="rules.password" clearable type="password" prepend-icon="mdi-lock" outlined :placeholder="selectedLanguage['costMapUpdate_component_text16']"/>
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="confirmDetailDialog=false">{{ selectedLanguage['costMapUpdate_component_text17'] }}</v-btn>
          <v-btn :color="color" :loading="loading1" dark variant="text" @click="clickDetailCalc(currentItem.number)">{{ selectedLanguage['costMapUpdate_component_text18'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Map from './Map.vue'
  import {apiAxios} from '../../helpers/interceptors'
  import store from '../../store'
  import NotificationMenu from '../../components/NotificationMenu.vue'
  import { portuguese } from '../../languages/pt-br'
  import { english } from '../../languages/en'

  export default {
    name: 'AdjustPrices',
    components:{Map,NotificationMenu},   
    computed: {
      steps () {
        return this.$store.getters.steps('railway')
      },
      status () {
        return this.$store.getters.status(this.$route.params.id)
      },
      ...mapGetters(['profile','storagedProps','alternativePolygon','track','buffer','vectors','vectorsType','baseLayers','balanceFormatted','currentCalc','currentExpropriation','currentCapex','currentSlope','alternativesDetail','alternatives']),
      srid () {
        try{
          if(this.currentCalc.srid){
            return this.currentCalc.srid
          }
          return 3857
        } catch (err){
          return 3857
        }
      },
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    mounted: async function(){
      await this.initVectors()
      await this.loadDetail()
      this.mapToggle = true
      setTimeout(()=>{
        this.mapHeight = document.getElementById('div1').getElementsByTagName( 'div' )[0].offsetHeight
      document.getElementById('vl-map-mapHeightStyle').style.height = (this.mapHeight) + 'px';
      this.$refs.mapComparative.testFeature()
      }, 1000)
    },
    data() {
      return {
        errorMessage: undefined,
        errorMessageMap: undefined,
        cloudPasswordInput: false,
        logoIconUrl: '/img/icons/logo_icon.webp',
        permanent: false,
        drawer: true,
        tab: 0,
        color: '#0A7373',
        loading1: false,
        loading2: false,
        loadingAlternativeReport: [false,false,false,false,false,false,false,false,false,false],
        currentItem: undefined,
        confirmDetailDialog: false,
        baseLayerIdx: 4,
        vectorsTypeSelected: [],
        compareAlternativesPdf: undefined,
        downloadingComparative: false,
        downloadingLayers: false,
        renderingComparative: false,
        drawType:undefined,
        testLimit:undefined,
        toggleLimitArea: true,
        togglePoint: true,
        toggleList: [],
        toggle: true,
        blockBtns: false,
        hasChanges: false,
        btnNext: true,
        mapToggle: false,
        itHasFeatureLength: 0,
        rules: {
          password: [
            value => !!value || 'Please enter a password',
            (value) => !!value || 'Please type password.',
            (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
            (value) => (value && value.length > 9 ) || 'minimum 10 characters',
          ]
        },
        cloudPassword: undefined,
      };
    },
    methods: {
      redirectFunc(){
        if(process.env.VUE_APP_VAR_SELECT== 'prd'){
          this.$router.push({ name: 'billingMercadoPago' })
        } else {
          this.$router.push({ name: 'billing' })
        }
      },
      LoadingSwitch(){
        this.loading1 = !this.loading1
      },
      clearDrawtype(){
      this.drawType = undefined
      this.$refs.mapComparative.clearMap2()
      },
      async goStep(step){
        await this.changeStatus({calcId: this.$route.params.id, payload: {'status': 'Init','step_name': this.steps[step-1], 'step': step, 'notification': this.status.notification}})
      },
      async clickSaveExit(){
        this.loading1 = true
        this.$emit('callBasic')
      },
      setRestrictedArea(){
        this.drawType = 'polygon';
      },
      async callChargeCredits(){
        this.$emit('callcharge')
        this.drawType = undefined
      },
      async updateLimitA(){
        await this.$refs.mapComparative.updateLimitArea()
      },
      checkFeatures(data){
        this.itHasFeatureLength = data
      },
      async callUpdateLimit(){
        this.drawType = undefined;
        this.loading1 = false
        this.btnNext = false
        await this.changeStatus({calcId: this.$route.params.id, payload: {"status": "HasChanges", "step_name": "Calculate alternatives", "step": 7}})
        await this.$refs.mapComparative.updateLimitArea()
        this.btnNext = true
      },
      handleDocumentRender() {
        this.renderingComparative = false
      },
      handleClick(value) {
        if(!value.status){
          this.currentItem=value
          this.confirmDetailDialog=true
        }
      },
      async compareAlternatives(id){
        this.downloadingComparative = true
        this.errorMessage = undefined
        apiAxios.get("/download/"+id+"/_/report_alternative/Comparativo.pdf", {responseType: "blob"})
        .then(res => {
          if(res.status==200){
            const blob = new Blob([res.data]);
            const objectUrl = URL.createObjectURL(blob);
            this.compareAlternativesPdf = objectUrl;
            this.downloadingComparative = false
            this.renderingComparative = true
            return true
          }
          else{
            this.errorMessage = res.statusText
            this.downloadingComparative = false
            return false
          }
        })
      },
      async getVector(index){
        var type = await store.getters.vectorsType[index]
        const res = await this.getVectorsByType({calcId: this.$route.params.id, vectorType: type, index: index})
        if(res!=true){
          this.errorMessageMap = res
          return false
        }
      },
      async uncheckedGetVector(){
        let i;
        for(i=0;i<store.getters.vectorsType.length;i++){
          if(this.toggleList[i] === false){
            delete store.getters.vectors[store.getters.vectorsType[i]]
          }
        }
      },
      async loadDetail(){
        this.downloadingLayers = true
        this.errorMessage = undefined
        const getCurrentCalcPromise = await this.getCurrentCalc(this.$route.params.id)
        const getLayersPromise = await this.getLayers(this.$route.params.id)
        const getVectorsTypePromise = await this.getVectorsType(this.$route.params.id)
        const getBuffer = await this.getBuffer(this.$route.params.id)

        await Promise.all([this.getLayers(this.$route.params.id),this.getCurrentCalc(this.$route.params.id),this.getVectorsType(this.$route.params.id), this.getBuffer(this.$route.params.id)])
        let res = await getCurrentCalcPromise
        if(res!=true){
          this.errorMessage = res
          this.downloadingLayers = false
          return false
        }
        res = await getLayersPromise
        if(res!=true){
          this.errorMessage = res
          this.downloadingLayers = false
          return false
        }
        res = await getVectorsTypePromise
        if(res!=true){
          this.errorMessage = res
          this.downloadingLayers = false
          return false
        }
        res = await getBuffer
        if(res!=true){
          this.errorMessage = res
          this.downloadingLayers = false
          return false
        }
        let i;
        for(i=0;i<store.getters.vectorsType.length;i++){
          this.toggleList.push(false)
        }
        await this.storageProps({vectorType: store.getters.vectorsType, srid: store.getters.currentCalc.srid})
        this.downloadingLayers = false
      },
      ...mapActions(['getBuffer','storageProps','changeStatus','basicCalc','initVectors','unsetGetVectorsByType','getCurrentCalc','getVectorsType', 'getVectorsByType','getLayers','updateCapex','changeStatus','detailedCalc','getAlternatives','useCredits','getMonetize','loginCloud','setCloudPassword']),
      save() {},
      cancel() {},
      open() {},
      close() {},
      capexItemStyle(item) {
        if(item.Unit!=0){ return 'capexLastLevel'}
        if(item.Level==0){ return 'capexZeroLevel'}
        if(item.Level==1){ return 'capexFirstLevel'}
        if(item.Level==2){ return 'capexSecondLevel'}
        if(item.Level==3){ return 'capexThirdLevel'}
        return ''
      },
      backToCalc() {
        this.$router.push('/type/railway/'+this.$route.params.id)
      },
      async clickCancelExit() {
        this.errorMessage = undefined
        this.loading2 = true
        const res = await this.goStep(1)
        if(res!=true){
          this.errorMessage = res
          this.loading2 = false
          return false
        }
        this.backToCalc()
        this.loading2 = false
      },
      async clickDetailCalc(number){
        this.errorMessage = undefined
        this.loading1=true
        let res = await this.useCredits({calcId: this.$route.params.id, alternativeNumber: number})
        if(res!=true){
          this.errorMessage = res
          this.loading1 = false
          return false
        }
        if(res==true){
          this.loadingAlternativeReport[number-1]=true
          this.getMonetize()
          this.confirmDetailDialog = false
          this.loading1=false
          this.currentItem=undefined
          await this.detailedCalc({calcId: this.$route.params.id, alternativeNumber: number})
          await this.getAlternatives(this.$route.params.id)
          this.loadingAlternativeReport[number-1] = false
        }
      },
    },
    watch: {
      vectorsTypeSelected: {
        handler() {
          if(this.toggleList[this.vectorsTypeSelected] === true){
            this.getVector(this.vectorsTypeSelected)
          } else {
            this.uncheckedGetVector()
          }
        }
      },
      toggle:{
        handler(){
          if(this.toggle === true){
            this.getBuffer(this.$route.params.id)
          }
        }
      }
    }
  }
</script>

<style scoped>
  .capexLastLevel {
    font-style: italic
  }
  .capexZeroLevel {
    background-color: #0A7373;
    font-weight: bold
  }
  .capexFirstLevel {
    background-color: #338D8C;
    font-weight: bold
  }
  .capexSecondLevel {
    background-color: #36bebc;
    font-weight: bold
  }
  .capexThirdLevel {
    background-color: #41e2df;
    font-weight: bold
  }
  .nominatim{
  display: none !important;
}
</style>